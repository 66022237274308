import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import moment from 'moment';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';

import {getCardGroups, getErrorMessage, getErrorMessage2, getParkingAccess} from 'utils';
import {CustomButton, Loading} from 'components/elements';
import {fetchParkingAccesses, processAction} from 'redux/actions/parkings';
import {fetchSubjectSearchOwners,fetchSubjectOwners, activateOwner, updateOwner} from 'redux/actions/owners';
import {fetchParkingCardGroups} from 'redux/actions/cards';
import CreateCardContent from './CreateCardContent';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Check from "@material-ui/icons/Check";

import {ArrowBackOutlined, Title} from "@material-ui/icons";
import CardOwnersTable from 'containers/Parking/SubjectParking/CardOwners/CardOwnersTable';
import {ActionButton} from 'components/common';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import {CustomCheckbox,CustomSelect} from 'components/elements';
import {getCodeList} from 'utils';
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    emptyContent: {
        display: 'flex',
        alignItems: 'center',
    },


    // mb: {
    //     marginBottom: theme.spacing(1.125),
    // },
    container: {
        '& .MuiDialog-paper': {
            width: 1800,
            height: 'fit-content',
            maxWidth: 'unset',
            minHeight: 500,
            boxShadow: theme.palette.shadow.main,
        },
    },
    container2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },
    table: {
        width: '100%',
        flexDirection: 'column',
        display: 'flex',
        padding: theme.spacing(1, 1, 1, 1),
        justifyContent: 'space-between',
    },
  
    title: {
        height: '15%',
        background: theme.palette.secondary.main,
        color: theme.palette.base[500],
        '& h2': {
            fontSize: 25,
            display: 'flex',
            flexDirection: 'column',
            marginTop: theme.spacing(2),
            color: theme.palette.base.white,

            '& span': {
                fontSize: 18,
                paddingTop: theme.spacing(0.5),
            },
        },
        '& .MuiTypography-root': {
            fontSize: 24,
        },
    },

    title2: {
        height: 60,
        width: '100%',
        background: 'green',
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: 20,
            marginBlockStart: 0,
            marginBlockEnd: 0,
            lineHeight: '20px',
            color: theme.palette.base.white,
            '& .MuiButtonBase-root': {
                color: theme.palette.base.white,
            },
            '& input + fieldset': {
                borderColor: theme.palette.base.white
            },
        },
    },

    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },

    actions: {
        marginTop: theme.spacing(5),
        justifyContent: 'center',
        textTransform: 'uppercase',
    },
    cancel: {
        height: 40,
        marginLeft: theme.spacing(3.75),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        color: theme.palette.primary.red,
        textTransform: 'uppercase',
    },
    containerOwner: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'center',
        paddingTop:'10px',
        paddingRight:'10px',
    },
    formGroup: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'left',

    '&:last-child': {
        marginBottom: 0,
    },
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        alignItems: 'left',
    },
    buttonWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
    },
    textField: {
        flex: 1,
        paddingLeft:'10px'
    },
    select: {
    flex: 1,

    '& .MuiSelect-select': {
        lineHeight: '20px',
        borderBottom: '1px solid #ADB0B8',
    },
    },
    mr: {
    marginRight: theme.spacing(2.5),
    minWidth: '160px',
    },
}));

const filterParams = {
    currentKeyword: '',
    validity: [''],
    size: 5,
    page: 0,
  };

function CreateGeneralCard(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        user,
        event,
        opened,
        parkings,
        subjectSearchOwners,
        parkingAccesses,
        parkingCardGroups,
        createCard,
        handleClose,
        handleCloseAndEdit,
        fetchSubjectOwners,
        fetchSubjectSearchOwners,
        fetchParkingAccesses,
        fetchParkingCardGroups,
        processAction,
        cardNumber,

        ownersPaging,
        subjectOwners,
        updateOwner,

        allCodeList,
        userUseCases
    } = props;

    const isUnMounted = useRef(false);
    useEffect(() => {
        isUnMounted.current = false;
        return () => {
            isUnMounted.current = true;
        };
    }, []);

    const [mode, setMode] = useState('COMMON');
    const isDisabled = false;

    const [validity, setValidity] = useState(['']);

    const [initSearchValue, setInitSearchValue] = useState('');
    
    const [isFetchedSearchOwners, setIsFetchedSearchOwners] = useState(true);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [ownerForm, setOwnerForm] = useState(false);

    const [size, setSize] = useState(5);
    const [page, setPage] = useState(0);

    const [isLoadingOwners, setIsLoadingOwners] = useState(true);
    const [isFetchedOwners, setIsFetchedOwners] = useState(false);

    const [errorMessage, setErrorMessage] = useState(null);

    const [isLoading, setIsLoading] = useState(false);
    const fetchCardDetail = useCallback(() => {
        if (
            user
            && user.subjectId
        ) {
            setIsLoading(true);
            const actions = [
                fetchParkingAccesses(event.parkingId, 'LT'),
                fetchParkingCardGroups(event.parkingId),
            ];

            Promise.all(actions)
                .then(() => {
                    if (!isUnMounted.current) {
                        setIsLoading(false);
                    }
                })
                .catch(() => {
                    if (!isUnMounted.current) {
                      setIsLoading(true);
                    }
                  });
        }
    }, [event.parkingId, fetchParkingAccesses, fetchParkingCardGroups, user]);


    useEffect(() => {
        if (!isFetchedOwners) {
            const validityStr = validity.map((valid) => {
                if (!valid) {
                  return '';
                }
          
                return valid === 'valid';
              });
              fetchSubjectOwners(user.subjectId, validityStr, searchKeyword, size, page)
              .then(() => {
                if (!isUnMounted.current) {
                    setIsFetchedOwners(true);
                    setIsLoadingOwners(false);
                    if (
                    JSON.stringify(filterParams.validity) !== JSON.stringify(validity)
                    || filterParams.size !== size
                    || filterParams.page !== page
                  ) {
                    setIsFetchedOwners(false);
                    setIsLoadingOwners(true);
                  }
                }
              })
              .catch(() => {
                if (!isUnMounted.current) {
                  setIsLoadingOwners(true);
                  setIsFetchedOwners(false);
                }
              });
        }
      }, [validity, isFetchedOwners, size, page, searchKeyword, fetchSubjectOwners]);

    useEffect(() => {
        fetchCardDetail();
    }, [fetchCardDetail]);

    const access = getParkingAccess(parkingAccesses);
    const cardGroups = getCardGroups(parkingCardGroups);

    const parking = parkings.find((p) => p.parkingId === event.parkingId);

    const zones = parking.zoneStatus || [];
    const zoneItems = zones.length > 0 ? zones.map((zone) => ({
        id: zone.zoneId,
        value: `zone_${zone.zoneId}`,
        label: zone.zoneName,
        key: `zone_${zone.zoneId}`,
    })) : [];

    const searchMenuData = subjectSearchOwners.length > 0
        ? subjectSearchOwners.map((owner) => ({
            key: owner.ownerId,
            label: `${owner.firstname} ${owner.surname}` || `owner_${owner.ownerId}`,
            value: owner.ownerId,
        }))
        : [];

    const fetchSearchOwners = useCallback((keyword) => {
        if (keyword) {
            fetchSubjectSearchOwners(keyword)
                .then(() => {
                    if (!isUnMounted.current) {
                        setIsFetchedSearchOwners(true);
                        if (filterParams.currentKeyword !== keyword) {
                            setIsFetchedSearchOwners(false);
                        }
                    }
                })
                .catch(() => {
                    if (!isUnMounted.current) {
                        setIsFetchedSearchOwners(false);
                    }
                });
        }
    }, [fetchSubjectSearchOwners]);

    const handleSearch = (value) => {
        filterParams.currentKeyword = value;
        setIsFetchedSearchOwners(false);
        setSearchKeyword(value);
    };

    useEffect(() => {
        if (!isFetchedSearchOwners) {
            fetchSearchOwners(searchKeyword);
        }
    }, [isFetchedSearchOwners, fetchSearchOwners, searchKeyword]);

    const [state, setState] = useState({
        cardGroups: [],
    });
    const [error, setError] = useState(null);

    const handleChange = (field) => (event) => {
        switch (field) {
            case 'credit':
            case 'lpn':
            case 'comment':
                return setState({
                    ...state,
                    [field]: event.target.value,
                });
            case 'cardNumber':
                return setState({
                    ...state,
                    [field]: event,
                });
            case 'cardGroups':
                return setState({
                    ...state,
                    [field]: event,
                });
            case 'firstname':
            case 'surname':
            case 'companyName':
            case 'note':
                return setState({
                    ...state,
                    [field]: event.target.value,
                });
            case 'phone1':
            case 'phone2':
                return event.target.value.toString().length < 31
                    && setState({
                        ...state,
                        [field]: event.target.value,
                    });
            default:
                return setState({
                    ...state,
                    [field]: event,
                });
        }
    };

    const searchMenuItemClick = (value) => {
        setState({
            ...state,
            owner: value,
        });
    };

    const handleCalculateCredit = () => {
        const validToTS = new Date(state.validTo).getTime();
        const validFromTS = new Date(state.validFrom).getTime();
        setState({
            ...state,
            credit: Math.floor((validToTS - validFromTS) / 60000),
        });
    };

    const handleSubmitAndEdit = (event) => {
        handleSubmit(event, true);
    }

    useEffect(() => {
            if (cardNumber) {
                if (opened) {
                    return setState({
                        ...state,
                        cardNumber,
                    });
                    processAction();
                }
            }
        }, [cardNumber]
    );


    const handleSubmit = (event, edit) => {
        const selectedOwner = state.owner && searchMenuData.length > 0
            ? searchMenuData.find((owner) => owner.value === state.owner)
            : null;

        if (!state.cardNumber || !state.credit || !state.access || !state.validFrom || !state.validTo || (searchKeyword && !selectedOwner)) {
            setError({
                cardNumber: Boolean(!state.cardNumber),
                credit: Boolean(!state.credit),
                access: Boolean(!state.access),
                validFrom: Boolean(!state.validFrom),
                validTo: Boolean(!state.validTo),
                owner: Boolean(searchKeyword && !selectedOwner),
            });
            return;
        }

        const zoneId = state.zone && zoneItems.length > 0 ? zoneItems.find((zi) => zi.value === state.zone).id : null;
        const selectedZone = zoneId ? zones.find((zone) => zone.zoneId === zoneId) : {};

        const reservedZoneId = state.zoneReservationId && zoneItems.length > 0
            ? zoneItems.find((zi) => zi.value === state.zoneReservationId).id
            : null;
        const reservedZone = reservedZoneId ? zones.find((zone) => zone.zoneId === reservedZoneId) : {};

        const selectedAccess = access.find((a) => a.value === state.access);

        setError(null);

        createCard({
            cardNumber: state.cardNumber,
            parkings: [
                {
                    parkingId: parking.parkingId,
                    parkingName: parking.parkingName,
                    zones: [{
                        zoneId: selectedZone.zoneId,
                        parkingId: parking.parkingId,
                        zoneName: selectedZone.zoneName,
                        zoneGmtpId: selectedZone.zoneGmtpId,
                        cardNumber: state.cardNumber,
                        lpn: state.lpn,
                        parkingName: parking.parkingName,
                        cardGroups: state.cardGroups,
                        owner: selectedOwner ? selectedOwner.label : null,
                        ownerId: selectedOwner ? selectedOwner.value : null,
                        note: state.comment,
                        type: event.cardType,
                        parkingAccessId: selectedAccess.id,
                        validFrom: moment(state.validFrom).format('MM.DD.YYYY HH:mm'),
                        validFromTS: new Date(state.validFrom).getTime(),
                        validTo: moment(state.validTo).format('MM.DD.YYYY HH:mm'),
                        validToTS: new Date(state.validTo).getTime(),
                        credit: state.credit,
                        startZoneId: selectedZone.zoneId,
                        zoneReservationId: reservedZone.zoneId,
                    }],
                },
            ],
        })
            .then(!edit && handleClose || edit && handleCloseAndEdit(parking.parkingId, state.cardNumber))
            .catch((error) => {
                if (error.status === 409) {
                    setError({
                        cardNumber: true,
                        duplicity: true
                    });
                } else {
                    setErrorMessage(getErrorMessage2(error, t));
                }

            });
    };

    const groupItems = getCodeList(allCodeList, 'OWNER_GROUP');
    const groups = groupItems.length > 0 ? groupItems.map((item) => ({
        value: `group_${item.codeListItemId}`,
        label: item.code,
        key: `group_${item.codeListItemId}`,
    })) : [];

    const resetPagination = () => {
      filterParams.page = 0;
      setPage(0);
    };
    const handleValidity = (value) => {
        let validity = value;
        if (validity.length === 3) {
        validity = [''];
        }
        filterParams.validity = validity;
        resetPagination();
        setIsFetchedOwners(false);
        setValidity(validity);
    };
    const handleChangeRowsPerPage = (value) => {
        filterParams.size = value;
        resetPagination();
        setIsFetchedOwners(false);
        setSize(value);
    };
    const handleChangePage = (value) => {
        filterParams.page = value;
        setIsFetchedOwners(false);
        setPage(value);
    };
    const handleSearchOwner = (value) => {
        filterParams.currentKeyword = value;
        resetPagination();
        setIsFetchedSearchOwners(false);
        setIsFetchedOwners(false);
        setSearchKeyword(value);
    };

    useEffect(() => {
        if (!isFetchedSearchOwners) {
          fetchSearchOwners(searchKeyword);
        }
    }, [isFetchedSearchOwners, fetchSearchOwners, searchKeyword]);

    const ownersData = subjectOwners.map((owner) => ({
        ownerId: owner.ownerId,
        firstName: owner.firstname,
        lastName: owner.surname,
        subjectName: owner.subjectName,
        valid: owner.valid,
        phone1: owner.phone1,
        phone2: owner.phone2,
        note: owner.note
    }));

    const handleActivate = (ownerId, activate) => () => {
        activateOwner(ownerId, activate);
    };

    const ownerDetailOpen = (event) => () => {
        handleOwnerDetailOpen(event);
        setOwnerForm(true);
    };

    const handleOwnerDetailOpen = (event) => {
        if ( event && event.ownerId ) {
            setState({
                ...state,
                owner: event.ownerId==null?"":event.ownerId,
                ownerId: event.ownerId==null?"":event.ownerId,
                firstname: event.firstName==null?"":event.firstName,
                surname: event.lastName==null?"":event.lastName,
                valid: event.valid==null?"":event.valid,
                phone1: event.phone1==null?"":event.phone1,
                phone2: event.phone2==null?"":event.phone2,
                note: event.note==null?"":event.note
            });
        } else {
            setState({
                ...state,
                owner: "",
                ownerId: "",
                firstname: "",
                surname: "",
                valid: "",
                phone1: "",
                phone2: "",
                note: ""
            });
        }
        setOwnerForm(false);
    }

    
    const handleSelectOwner = (event) => {
        setMode('COMMON');
        setInitSearchValue(event.ownerLabes);
        setState({
            ...state,
            owner: event.ownerId,
            ownerId: "",
            firstname: "",
            surname: "",
            valid: "",
            phone1: "",
            phone2: "",
            note: ""
        });
    }

    const handleCancle = () => () => {
        handleOwnerDetailOpen();
        setOwnerForm(false);
    };

    const handleSave = () => () => {
        updateOwner({
          firstname: state.firstname,
          surname: state.surname,
          phone1: state.phone1,
          phone2: state.phone2,
          note: state.note,
          valid: state.valid,
          group: state.group,
          ownerId: state.ownerId,
          subjectId: state.subjectId!==null?state.subjectId:user.subjectId,
        })
          .then(()=>{
            handleSelectOwner({
              ownerId: state.ownerId,
              firstname: state.firstname,
              surname: state.surname,
              distinct: ''
            });
            setMode('COMMON');
            setState({
                ...state,
                owner: "",
                ownerId: "",
                firstname: "",
                surname: "",
                valid: "",
                phone1: "",
                phone2: "",
                note: ""
            });
          });
  
        setInitSearchValue(state.firstname + ' ' + state.surname);
        setIsFetchedOwners(false);
    }


    const ownerDetailShow = (
        <>
            <div className={classes.title2}>
                <h2>{state.ownerId ? t('CARD_OWNER_UPDATE') : t('CARD_OWNER_CREATE')}</h2>
            </div>

            <Paper className={classes.table}>

                <Box className={classes.formGroup}>
                    <Box className={classes.wrapper}>
                        <TextField
                          label={t('CARD_OWNER_NAME')}
                          autoFocus
                            required
                            className={classes.textField}
                            hiddenLabel
                            disabled={isDisabled}
                            value={state.firstname}
                            onChange={handleChange('firstname')}
                        />
                    </Box>

                    <Box className={classes.wrapper}>
                        <TextField
                            required
                            label={t('CARD_OWNER_SURNAME')}
                            className={classes.textField}
                            hiddenLabel
                            disabled={isDisabled}
                            value={state.surname}
                            onChange={handleChange('surname')}
                        />
                    </Box>
                </Box>

                <Box className={classes.formGroup}>
                    <Box className={classes.wrapper}>
                        <TextField
                            required
                            label={t('CARD_OWNER_PHONE_1')}
                            className={classes.textField}
                            hiddenLabel
                            disabled={isDisabled}
                            value={state.phone1}
                            type="number"
                            onChange={handleChange('phone1')}
                        />
                    </Box>

                    <Box className={classes.wrapper}>
                        <TextField
                            required
                            label={t('CARD_OWNER_PHONE_2')}
                            className={classes.textField}
                            hiddenLabel
                            disabled={isDisabled}
                            value={state.phone2}
                            type="number"
                            onChange={handleChange('phone2')}
                        />
                    </Box>
                </Box>

                <Box className={classes.formGroup}>
                    <Box className={classes.wrapper}>
                        <CustomSelect
                            label={t('CARD_OWNER_GROUPS')}
                            value={state.group || ''}
                            items={groups}
                            className={classes.select}
                            disabled={isDisabled}
                            placeholder={t('CARD_OWNER_GROUPS')}
                            handleChange={handleChange('group')}
                        />
                    </Box>

                    <Box className={classes.wrapper}>
                        <CustomCheckbox
                            label={t('CARD_OWNER_VALIDITY')}
                            checked={state.valid}
                            disableCheck={isDisabled}
                            handleChange={handleChange('valid')}
                        />
                    </Box>
                </Box>

                <Box className={classes.formGroup}>
                    <TextField
                        className={classes.textField}
                        label={t('CARD_OWNER_NOTE')}
                        hiddenLabel
                        multiline
                        rowsMax="2"
                        inputProps={{
                            maxLength: 150,
                        }}
                        disabled={isDisabled}
                        value={state.note}
                        helperText={!isDisabled && (
                            <span>
                    {`${150 - (state.note ? state.note.length : 0)} characters left`}
                </span>
                        )}
                        onChange={handleChange('note')}
                    />
                </Box>

                <div className={classes.buttonWrapper}>
                <ActionButton
                    handleClick={handleSave()}
                    className={classes.mr}
                    action="save"
                />
                <ActionButton
                    action="cancle"
                    className={classes.mr}
                    handleClick={handleCancle()}
                />
                </div>
            </Paper>
        </>
    )

    return (
        <Dialog
            className={classes.container}
            open={opened}
            onClose={handleClose}
            disableEnforceFocus
        >
            <DialogTitle className={classes.title}>
                {t('CREATE_GENERAL_CARD')}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <div className={mode==='COMMON'?classes.container:classes.containerOwner}>

                {mode==='COMMON' &&
                    <DialogContent className={isLoading ? classes.emptyContent : null}>
                        {isLoading ? (
                            <Loading/>
                        ) : (
                            <CreateCardContent
                                state={state}
                                error={error}
                                errorMessage={errorMessage}
                                setErrorMessage={setErrorMessage}
                                access={access}
                                cardGroups={cardGroups}
                                zoneItems={zoneItems}
                                searchMenuData={searchMenuData}
                                handleSearch={handleSearch}
                                searchMenuItemClick={searchMenuItemClick}
                                handleChange={handleChange}
                                handleCalculateCredit={handleCalculateCredit}
                                setMode={setMode}
                                initSearchValue={initSearchValue}
                            />
                        )}
                    </DialogContent>
                }

                {mode!=='COMMON' &&
                <IconButton onClick={() => {
                    setMode('COMMON')
                }} className={classes.iconButton}>
                    <ArrowBackOutlined className={classes.icon}/>
                </IconButton>
                }
                {mode==='OWNER' &&
                <Grid container spacing={2}>
                    <Grid item md={ownerForm ? 8:12}>
                        <CardOwnersTable
                            userUseCases={userUseCases}
                            ownersData={ownersData}
                            subjectSearchOwners={subjectSearchOwners}
                            ownersPaging={ownersPaging}
                            isLoadingOwners={isLoadingOwners}
                            validity={validity}
                            ownerDetailOpen={ownerDetailOpen}
                            handleOwnerDetailOpen={handleSelectOwner}
                            handleActivate={handleActivate}
                            handleValidity={handleValidity}
                            handleSearch={handleSearchOwner}
                            handleChangeRowsPerPage={handleChangeRowsPerPage}
                            handleChangePage={handleChangePage}
                            showForm={setOwnerForm}
                            options={{
                            rowStyle: (rowData) => ({
                                backgroundColor:
                                state.selectedRow === rowData.tableData.id ? "#F0F0F0" : "#FFF",
                            }),}
                            }
                        />
                    </Grid>
                    { ownerForm &&
                    <Grid item md='4'>
                        {ownerDetailShow}
                    </Grid>}
                </Grid>
            }  
            </div>

            <DialogActions className={classes.actions}>
                <CustomButton
                    label={t('CLOSE')}
                    color="grey"
                    width={180}
                    onClick={handleClose}
                />
                <CustomButton
                    label={t('CREATE_CARD')}
                    muiIcon={<Check/>}
                    color="green"
                    width={180}
                    onClick={handleSubmit}
                />
                <CustomButton
                    label={t('CREATE_CARD_AND_EDIT')}
                    muiIcon={<Check/>}
                    color="green"
                    width={230}
                    onClick={handleSubmitAndEdit}
                />
            </DialogActions>
        </Dialog>
    );
}

CreateGeneralCard.propTypes = {
    user: PropTypes.object,
    event: PropTypes.object.isRequired,
    opened: PropTypes.bool.isRequired,
    parkings: PropTypes.array.isRequired,
    subjectSearchOwners: PropTypes.array.isRequired,
    parkingAccesses: PropTypes.array.isRequired,
    parkingCardGroups: PropTypes.array.isRequired,
    createCard: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    fetchSubjectOwners: PropTypes.func.isRequired,
    fetchSubjectSearchOwners: PropTypes.func.isRequired,
    fetchParkingAccesses: PropTypes.func.isRequired,
    fetchParkingCardGroups: PropTypes.func.isRequired,
};

CreateGeneralCard.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    subjectOwners: store.ownersData.subjectOwners,
    ownersPaging: store.ownersData.ownersPaging,
    subjectSearchOwners: store.ownersData.subjectSearchOwners,
    parkingAccesses: store.parkingsData.parkingAccesses,
    parkingCardGroups: store.cardsData.parkingCardGroups,
    allCodeList: store.codesData.allCodeList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchParkingAccesses,
    fetchParkingCardGroups,
    fetchSubjectSearchOwners,
    processAction,
    fetchSubjectOwners,
    activateOwner,
    updateOwner,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateGeneralCard);
