import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme }from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import {CustomSelect, CustomDateTimePicker, CustomButton, CustomMultiSelect} from 'components/elements';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(1.125),
    display: 'flex',
  },
  mb: {
    marginBottom: theme.spacing(1.125),
  },
  borderBottom: {
    borderBottom: `1px solid ${theme.palette.base[200]}`,
  },
  formGroup: {
    marginBottom: theme.spacing(1.5),
    display: 'flex',
    alignItems: 'center',

    '&:last-child': {
      marginBottom: 0,
      marginTop: theme.spacing(3),
    },
  },
  label: {
    width: theme.spacing(11),
    marginRight: theme.spacing(3),
    color: '#A2A2A2',
    textAlign: 'right',
  },
  rightLabel: {
    fontSize: 14,
    width: theme.spacing(10),
    marginRight: theme.spacing(3),
    marginLeft: theme.spacing(3),
    color: theme.palette.base[300],
    textAlign: 'right',
  },
  content: {
    padding: theme.spacing(1, 0),
    flex: 1,

    '& .MuiSelect-select': {
      borderBottom: '1px solid #ADB0B8',
      color: theme.palette.base[800],
      fontSize: 16,
    },

    '& input': {
      color: theme.palette.base[800],
      fontSize: 16,
      paddingLeft: theme.spacing(1.125),
    },

    '& svg': {
      width: 24,
      height: 24,
      fill: theme.palette.base[300],
      marginTop: 0,
    },

    '& > div': {
      display: 'flex',
      flex: 1,
      marginLeft: 0,
    },
  },
  textField: {
    flex: 1,
  },
  errorMessage: {
    color: theme.palette.primary.red,
    fontSize: 13,
  },
  select: {
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(1.125),
      lineHeight: '20px',
    },
  },
  selectGroupe: {
    '& .MuiSelect-select': {
      paddingLeft: theme.spacing(1.125),
      lineHeight: '25px',
    },
    width: '100%',
    maxWidth:500,
  },
  inputField: {
    width: '60%',
  },
  actionField: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(2, 6, 0, 4),
  },
  actionButton: {
    marginBottom: theme.spacing(2.5),
    textTransform: 'uppercase',
  },
  zoneLabel: {
    marginLeft: 0,
    textAlign: 'left',
    width: theme.spacing(15),
  },
  zoneAccessLabel: {
    margin: 0,
    textAlign: 'left',
  },
  zoneAccess: {
    paddingLeft: theme.spacing(3),
  },
  zones: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  zone: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
  },
  splitter: {
    position: 'absolute',
    left: 0,
    width: '100%',
    height: 2,
    background: theme.palette.base[200],
  },
  invalid: {
    '& input': {
      color: `${theme.palette.primary.red}`,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const getStyles = (value, items, theme) => ({
  fontWeight:
    items.indexOf(value) === -1
      ? theme.typography.fontWeightRegular
      : theme.typography.fontWeightMedium,
});

function EditCardsContent(props) {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    state,
    type,
    zones,
    accesses,
    cardGroups,
    handleChange,
    handleSubmit,
  } = props;

  const isValid = () => {
    if (state.validTo && state.validFrom) {
      const validToTS = new Date(state.validTo).getTime();
      const validFromTS = new Date(state.validFrom).getTime();

      return validToTS >= validFromTS;
    }

    return true;
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.inputField}>
        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('ACCESS')}</Typography>
          <Box className={classes.content}>
            <CustomSelect
                autoFocus
              value={state.access}
              items={accesses}
              placeholder={t('ACCESS')}
              className={classes.select}
              disableValues={[type]}
              handleChange={handleChange('access')}
            />
          </Box>
        </Box>

        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('CARD_GROUP')}</Typography>
          <Box className={classes.content}>
            <CustomMultiSelect
              className={classes.selectGroupe}
              items={cardGroups}
              label={t('CARD_GROUP')}
              value={state.cardGroups}
              handleChange={handleChange('cardGroups')}
              fromGroupe="true"
            />
          </Box>
        </Box>

        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('ZONE')}</Typography>
          <Box className={classes.content}>
            <CustomSelect
              value={state.zone}
              items={zones}
              placeholder={t('ZONE')}
              className={classes.select}
              handleChange={handleChange('zone')}
            />
          </Box>
        </Box>

        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('VALID_FROM')}</Typography>
          <Box
            className={classnames(
              classes.content,
              !isValid() && classes.invalid,
            )}
          >
            <CustomDateTimePicker
              value={state.validFrom || null}
              icon={<ArrowDropDown />}
              handleChange={handleChange('validFrom')}
            />
          </Box>
        </Box>

        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('VALID_TO')}</Typography>
          <Box
            className={classnames(
              classes.content,
              !isValid() && classes.invalid,
            )}
          >
            <CustomDateTimePicker
              value={state.validTo || null}
              icon={<ArrowDropDown />}
              handleChange={handleChange('validTo')}
            />
          </Box>
        </Box>

        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('ZONE_RESERVATION')}</Typography>
          <Box className={classes.content}>
            <CustomSelect
              value={state.zoneReservationId}
              items={zones}
              placeholder={t('ZONE_RESERVATION')}
              className={classes.select}
              handleChange={handleChange('zoneReservationId')}
            />
          </Box>
        </Box>

        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('COMMENT')}</Typography>
          <Box className={classes.content}>
            <TextField
              className={classes.textField}
              required
              multiline
              rowsMax="2"
              hiddenLabel
              value={state.comment || ''}
              onChange={handleChange('comment')}
            />
          </Box>
        </Box>

        <Box className={classnames(classes.mb, classes.formGroup)}>
          <Typography variant="button" className={classes.label}>{t('BLOCKING_REASON')}</Typography>
          <TextField
            required
            className={classes.textField}
            hiddenLabel
            value={state.blockingReason || ''}
            onChange={handleChange('blockingReason')}
          />
        </Box>
      </div>

      <div className={classes.actionField}>
        <CustomButton
          className={classes.actionButton}
          label={t('SET_ACCESS')}
          width={200}
          disabled={!state.access}
          onClick={handleSubmit('access')}
        />
        <CustomButton
          className={classes.actionButton}
          label={t('SET_CARD_GROUP')}
          width={200}
          disabled={!state.cardGroups}
          onClick={handleSubmit('cardGroups')}
        />
        <CustomButton
          className={classes.actionButton}
          label={t('SET_ZONE')}
          width={200}
          disabled={!state.zone}
          onClick={handleSubmit('zone')}
        />
        <CustomButton
          className={classes.actionButton}
          label={t('SET_VALID_FROM')}
          width={200}
          disabled={!state.validFrom}
          onClick={handleSubmit('validFrom')}
        />
        <CustomButton
          className={classes.actionButton}
          label={t('SET_VALID_TO')}
          width={200}
          disabled={!state.validTo}
          onClick={handleSubmit('validTo')}
        />
        <CustomButton
          className={classes.actionButton}
          label={t('SET_ZONE_RESERVATION')}
          width={200}
          disabled={!state.zoneReservationId}
          onClick={handleSubmit('zoneReservationId')}
        />
        <CustomButton
          className={classes.actionButton}
          label={t('SET_COMMENT')}
          width={200}
          disabled={!state.comment}
          onClick={handleSubmit('comment')}
        />
      </div>
    </div>
  );
}

EditCardsContent.propTypes = {
  state: PropTypes.object.isRequired,
  zones: PropTypes.array.isRequired,
  type: PropTypes.string,
  accesses: PropTypes.array.isRequired,
  cardGroups: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

EditCardsContent.defaultProps = {
  type: undefined,
};

export default EditCardsContent;
