import ownersService from 'services/owners.service';
import types from '../actionTypes';

export const fetchSubjectOwners = (subjectId, validity, search, size, page) => (dispatch, getState) => {
    if (getState().ownersData.isFetchingSubjectList) {
        return Promise.reject();
    }

    dispatch({
        type: types.SUBJECT_OWNERS_FETCH_REQUEST,
    });
    return ownersService.getSubjectOwners(subjectId, validity ? validity.toString().slice(1) : null, search ? search : null, size, page)
        .then((data) => {
            dispatch({
                type: types.SUBJECT_OWNERS_FETCH_SUCCESS,
                payload: {subjectOwners: data.owenCards, paging: data.paging},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SUBJECT_OWNERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const fetchSubjectSearchOwners = (keyword) => (dispatch, getState) => {
    if (getState().ownersData.isFetchingSearchList) {
        return Promise.reject();
    }

    dispatch({
        type: types.SUBJECT_SEARCH_OWNERS_FETCH_REQUEST,
    });

    return ownersService.getSubjectSearchOwners(keyword)
        .then((subjectSearchOwners) => {
            dispatch({
                type: types.SUBJECT_SEARCH_OWNERS_FETCH_SUCCESS,
                payload: {subjectSearchOwners},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.SUBJECT_SEARCH_OWNERS_FETCH_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const newOwner = () => (dispatch) => {
    dispatch({
        type: types.NEW_OWNER,
        payload: {ownerDetail: {valid: true}},
    });
};

export const fetchOwnerDetail = (ownerId) => (dispatch, getState) => {
    if (getState().ownersData.isFetchingOwnerDetail) {
        return Promise.reject();
    }

    dispatch({
        type: types.OWNER_DETAIL_REQUEST,
    });

    return ownersService.getOwnerDetail(ownerId)
        .then((ownerDetail) => {
            dispatch({
                type: types.OWNER_DETAIL_SUCCESS,
                payload: {ownerDetail},
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.OWNER_DETAIL_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const updateOwner = (owner) => (dispatch, getState) => {
    if (getState().ownersData.isUpdating) {
        return;
    }

    dispatch({
        type: types.OWNER_UPDATE_REQUEST,
    });

    return ownersService.updateOwner(owner)
        .then((data) => {
            dispatch({
                type: types.OWNER_UPDATE_SUCCESS,
                payload: {data},
            });
            dispatch(fetchSubjectOwners(owner.subjectId));
            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.OWNER_UPDATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};

export const activateOwner = (ownerId, activate) => (dispatch, getState) => {
    if (getState().ownersData.isActivating) {
        return;
    }

    dispatch({
        type: types.OWNER_ACTIVATE_REQUEST,
    });

    return ownersService.setOwnerActivate(ownerId, activate)
        .then(() => {
            const {subjectOwners} = getState().ownersData;
            const subjectOwnersC = subjectOwners.map(
                (item) => (item.ownerId === ownerId ? {...item, valid: activate}
                    : item),
            );
            dispatch({
                type: types.OWNER_ACTIVATE_SUCCESS,
                payload: {
                    subjectOwners: subjectOwnersC,
                },
            });

            return true;
        })
        .catch((error) => {
            dispatch({
                type: types.OWNER_ACTIVATE_FAIL,
                payload: {error},
            });

            throw error;
        });
};
