import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import PropTypes from 'prop-types';

import {Loading} from 'components/elements';
import App from './App';
import {
    callsFromLocaleStorage,
    callsToLocaleStorage,
    getQueryVariable,
    logLevelType,
    SIP_STATUS,
    isComponentVisibleForUser,
    isComponentEnabledForUser
} from './utils';


let callConfig; // SIP session configuration
let remoteAudio;
let sipStack;
let sipSessionRegister;
let sipSessionCall;
let
    sipSessionTransferCall;
let notification;
let calls = callsFromLocaleStorage(JSON.parse(localStorage.getItem('sip_calls'))) || [];
const PAUSE_NUMBER = '*70005';
const UNPAUSE_NUMBER = '*70006';


function initializeSIPml5(setSipStatus, setCalls, user) {
    if (window.mozRTCPeerConnection) {
        window.mozRTCPeerConnection.prototype.removeStream = function (stream) {
            this.getSenders().forEach((sender) => stream.getTracks().includes(sender.track) && this.removeTrack(sender));
        };
    }
    const sipml5ReadyCallbackWithSetSipStatus = sipml5ReadyCallback.bind(this, setSipStatus, setCalls, user);
    window.SIPml.init(sipml5ReadyCallbackWithSetSipStatus, sipml5ErrorCallback);
}

/*
 * Callback function to call when the stack finishes initializing and becomes ready.
 */
function sipml5ReadyCallback(setSipStatus, setCalls, user) {
    // check for WebRTC support
    if (!window.SIPml.isWebRtcSupported()) {
        logMessage('WebRTC is not supported!', window.window.level = logLevelType.FATAL);
        return;
    }
    // check for WebSocket support
    if (!window.SIPml.isWebSocketSupported()) {
        logMessage('WebSocket is not supported!', window.level = logLevelType.FATAL);
        return;
    }
    callConfig = {
        audio_remote: remoteAudio,
        bandwidth: {audio: undefined, video: undefined},
        events_listener: {events: '*', listener: getOnSipSessionEvent(setSipStatus, setCalls)},
        sip_caps: [
            {name: '+g.oma.sip-im'},
            {name: 'language', value: '"en"'},
        ],
    };
    sipRegister(setSipStatus, setCalls, user);
}

/*
 * Callback function to call when the stack initialization fails.
 */
function sipml5ErrorCallback(err) {
    logMessage(`An error occured while initializing the SIP stack: ${err}`, window.level = logLevelType.ERROR);
}


function callTerminated(description) {
    sipSessionCall = null;

    stopRingbackTone();
    stopRingTone();

    logMessage(description, window.level = logLevelType.INFO);

    if (notification) {
        notification.cancel();
        notification = null;
    }
}

/* function onKeypadPanelMouseMove(evt) {
    try { // Internet Explorer may choke due the DOM not being ready
        if (window.tsk_utils_have_stream()) {
            window.btnCall.disabled = (!window.tsk_utils_have_stream() || !sipSessionRegister || (sipSessionRegister.hasOwnProperty('is_connected') && !sipSessionRegister.is_connected()));
            window.keypad.onmousemove = null; // unsubscribe
        }
    } catch (exc) {
        logMessage("Error in onKeypadPanelMouseMove(" + evt + "): " + exc);
    }
} */


function sipRegister(setSipStatus, setCalls, user) {
    try { // catch exception for IE (DOM not ready)
        if (window.webkitNotifications && window.webkitNotifications.checkPermission() != 0) {
            window.webkitNotifications.requestPermission();
        }

        const onSipStackEventWithSetStatus = (function (setSipStatus, setCalls) {
            return function (e) {
                let failure;
                let remoteNumber;
                window.tsk_utils_log_info(`==stack event = ${e.type}`);

                switch (e.type) {
                    case 'started': {
                        try {
                            sipSessionRegister = this.newSession('register', {
                                expires: 200,
                                events_listener: {events: '*', listener: getOnSipSessionEvent(setSipStatus, setCalls)}, // FIXME: *Session* event, really?
                                sip_caps: [
                                    {name: '+g.oma.sip-im', value: null},
                                    {name: '+audio', value: null},
                                    {name: 'language', value: '"en"'},
                                ],
                            });
                            sipSessionRegister.register();
                            logMessage('SIP Session registered.', window.level = logLevelType.INFO);
                        } catch (exc) {
                            logMessage(`SIP REGISTER: ${exc}`);
                        }
                        break;
                    }

                    case 'stopping':
                    case 'stopped':
                    case 'failed_to_start':
                    case 'failed_to_stop': {
                        failure = (e.type === 'failed_to_start') || (e.type === 'failed_to_stop');
                        sipStack = null;
                        sipSessionRegister = null;
                        sipSessionCall = null;

                        setSipStatus(SIP_STATUS.DISCONNECTED);

                        stopRingbackTone();
                        stopRingTone();

                        if (failure) {
                            logMessage(`Disconnected: ${+e.description}.`, window.level = logLevelType.ERROR);
                        } else {
                            logMessage('Disconnected.', window.level = logLevelType.INFO);
                        }
                        break;
                    }

                    case 'i_new_call': {
                        if (sipSessionCall) {
                            // do not accept the incoming call if we're already "in call"
                            e.newSession.hangup(); // comment this line for multi-line support
                        } else {
                            sipSessionCall = e.newSession;
                            // start listening for events
                            sipSessionCall.setConfiguration(callConfig);
                            startRingTone();
                            remoteNumber = (sipSessionCall.getRemoteFriendlyName() || 'unknown');
                            logMessage(`Incoming call from ${remoteNumber}.`, window.level = logLevelType.WARNING);
                            showNotification('Incoming call', `Incoming call from ${remoteNumber}`);
                            setSipStatus(SIP_STATUS.NEW_CALL);
                        }
                        break;
                    }

                    case 'm_permission_requested': {
                        if (window.divGlassPanel) {
                            window.divGlassPanel.style.visibility = 'visible';
                        }
                        break;
                    }

                    case 'm_permission_accepted':
                    case 'm_permission_refused': {
                        if (window.divGlassPanel) {
                            window.divGlassPanel.style.visibility = 'hidden';
                        }
                        if (e.type === 'm_permission_refused') {
                            callTerminated('Media stream permission denied');
                        }
                        break;
                    }

                    case 'starting': {
                        break;
                    }

                    default: {
                        break;
                    }
                }
            };
        }(setSipStatus, setCalls));

        sipStack = new window.SIPml.Stack({
            realm: process.env.REACT_APP_SIP_DOMAIN,
            impi: user.sipUserName,
            impu: `sip:${user.sipUserName}@${process.env.REACT_APP_SIP_DOMAIN}`,
            password: user.sipPassword,
            display_name: `Klient ${user.sipUserName}`, // test-soft",
            websocket_proxy_url: process.env.REACT_APP_SIP_URL,
            enable_rtcweb_breaker: true,
            events_listener: {events: '*', listener: onSipStackEventWithSetStatus},
            enable_early_ims: true,
            bandwidth: null,
            // ice_servers: [],
            // ice_servers: [{
            //     url: process.env.REACT_APP_STUN_URL,
            //     credential: process.env.REACT_APP_STUN_PASSWORD,
            //     username: process.env.REACT_APP_STUN_USER,
            // }, {
            //     url: process.env.REACT_APP_TURN_URL,
            //     credential: process.env.REACT_APP_TURN_PASSWORD,
            //     username: process.env.REACT_APP_TURN_USER,
            // }],
            ice_servers: [{
                url: process.env.REACT_APP_TURN_URL,
                credential: process.env.REACT_APP_TURN_PASSWORD,
                username: process.env.REACT_APP_TURN_USER,
            }],
            sip_headers: [
                {name: 'User-Agent', value: 'IM-client/OMA1.0 GreenCenter'},
                {name: 'Organization', value: 'Green Center s.r.o.'},
            ],
        });

        if (sipStack.start() != 0) {
            logMessage('Failed to start the SIP stack.', window.level = logLevelType.FATAL);
        } else {
            logMessage('SIP Stack initialized successfully.', window.level = logLevelType.DEBUG);
        }
    } catch (exc) {
        logMessage(`An error occured in sipRegister(): ${exc}`, window.level = logLevelType.FATAL);
    }
}

function sipUnregister() {
    if (sipStack) {
        sipStack.stop(); // shutdown all sessions
    }
}

function sipCall(type, destination) {
    if (sipStack && !sipSessionCall && !window.tsk_string_is_null_or_empty(destination)) { // FIXME
        sipSessionCall = sipStack.newSession(type, callConfig);
        if (sipSessionCall.call(destination) != 0) { // FIXME
            sipSessionCall = null;
            logMessage('Failed to make a call.', window.level = logLevelType.ERROR);
        }
    } else if (sipSessionCall) {
        logMessage('Connecting.', window.level = logLevelType.INFO);
        sipSessionCall.accept(callConfig);
    }
}


function reject(setSipStatus, setCalls, action) {
    if (sipSessionCall) {
        const ret = sipSessionCall.reject(callConfig);
        calls.unshift({
            date: new Date(),
            rejected: true,
            number: (sipSessionCall.getRemoteFriendlyName() || 'unknown'),
        });
        localStorage.setItem('sip_calls', JSON.stringify(callsToLocaleStorage(calls)));
        setCalls(calls);
    }
}

function sipHangUp(setSipStatus, setCalls, action) {
    if (sipSessionCall) {
        logMessage('Terminating the call.', action);
        if (action && action.newIncomingCallDeclined) {
            calls.unshift({
                date: new Date(),
                rejected: true,
                number: (sipSessionCall.getRemoteFriendlyName() || 'unknown'),
            });
            localStorage.setItem('sip_calls', JSON.stringify(callsToLocaleStorage(calls)));
            setCalls(calls);
        }
        const ret = sipSessionCall.hangup({
            events_listener: {
                events: '*',
                listener: getOnSipSessionEvent(setSipStatus, setCalls),
            },
        });
    }
}


function getFromCallNumberFromSession(e) {
    console.warn(e.o_event.o_session.o_uri_to);
    return e.o_event && e.o_event.o_session && e.o_event.o_session.o_uri_to && e.o_event.o_session.o_uri_to.s_user_name || 'unknown';
}

// Callback for SIP sessions (INVITE, REGISTER, MESSAGE, ...)

function getOnSipSessionEvent(setSipStatus, setCalls) {
    return function onSipSessionEvent(e) {
        let connected;
        let sipResponseCode;
        let msg;
        let msgLevel;

        window.tsk_utils_log_info(`==session event = ${e.type}`);
        switch (e.type) {
            case 'connecting':
            case 'connected': {
                connected = (e.type === 'connected');

                if (e.session === sipSessionRegister) {
                    setSipStatus(SIP_STATUS.CONNECTED);
                    logMessage(e.description);
                } else if (e.session === sipSessionCall) {
                    if (connected) {
                        stopRingbackTone();
                        stopRingTone();

                        if (notification) {
                            notification.cancel();
                            notification = null;
                        }
                        calls.unshift({
                            date: new Date(),
                            accepted: true,
                            number: (sipSessionCall.getRemoteFriendlyName() || 'unknown'),
                        });
                        localStorage.setItem('sip_calls', JSON.stringify(callsToLocaleStorage(calls)));
                        setCalls(calls);
                        setSipStatus(SIP_STATUS.IN_CALL);
                    } else {
                        calls.unshift({
                            date: new Date(),
                            out: true,
                            number: (sipSessionCall.getRemoteFriendlyName() || 'unknown'),
                        });
                        localStorage.setItem('sip_calls', JSON.stringify(callsToLocaleStorage(calls)));
                        setCalls(calls);
                        setSipStatus(SIP_STATUS.NEW_OUT_GOING_CALL);
                    }
                    logMessage(e.description);
                }
                break;
            }

            case 'terminating':
            case 'terminated': {
                if (e.session === sipSessionRegister) {
                    setSipStatus(SIP_STATUS.DISCONNECTED);

                    sipSessionCall = null;
                    sipSessionRegister = null;

                    logMessage(`${e.description}.`);
                } else if (e.session === sipSessionCall) {
                    callTerminated(`${e.description}.`);
                    const phoneNumber = getFromCallNumberFromSession(e);
                    if (phoneNumber !== PAUSE_NUMBER) {
                        if (e.description === 'Request Cancelled') {
                            calls.unshift({
                                date: new Date(),
                                missed: true,
                                number: e.session.getRemoteFriendlyName(),
                            });
                            localStorage.setItem('sip_calls', JSON.stringify(callsToLocaleStorage(calls)));
                            setCalls(calls);
                        }
                        setSipStatus(SIP_STATUS.CONNECTED);
                    } else {
                        setSipStatus(SIP_STATUS.PAUSED);
                    }
                }

                break;
            }
            case 'i_ect_new_call': {
                sipSessionTransferCall = e.session;

                break;
            }

            case 'i_ao_request': {
                if (e.session === sipSessionCall) {
                    sipResponseCode = e.getSipResponseCode();
                    if (sipResponseCode == 180 || sipResponseCode == 183) {
                        startRingbackTone();
                        logMessage('Remote ringing...', window.level = logLevelType.INFO);
                    }
                }
                break;
            }

            case 'm_early_media': {
                if (e.session === sipSessionCall) {
                    stopRingbackTone();
                    stopRingTone();
                    logMessage('Early media started.', window.level = logLevelType.INFO);
                }
                break;
            }

            case 'm_local_hold_ok': {
                if (e.session === sipSessionCall) {
                    if (e.session === sipSessionCall.transfering) {
                        sipSessionCall.transfering = false;
                    }
                    logMessage('Call placed on hold.', window.level = logLevelType.WARNING);
                    sipSessionCall.held = true;
                }
                break;
            }

            case 'm_local_hold_nok': {
                if (e.session === sipSessionCall) {
                    sipSessionCall.transfering = false;
                    logMessage('Failed to place remote party on hold.', window.level = logLevelType.ERROR);
                }
            }

            case 'm_local_resume_ok': {
                if (e.session === sipSessionCall) {
                    sipSessionCall.transfering = false;
                    logMessage('Call taken off hold.', window.level = logLevelType.INFO);
                    sipSessionCall.held = false;

                    if (window.SIPml.isWebRtc4AllSupported()) { // IE doesn't provide stream callback yet
                        /* EMPTY */
                    }
                }
                break;
            }

            case 'm_local_resume_nok': {
                if (e.session === sipSessionCall) {
                    sipSessionCall.transfering = false;
                    logMessage('Failed to unhold call.', window.level = logLevelType.ERROR);
                }
                break;
            }

            case 'm_remote_hold': {
                if (e.session === sipSessionCall) {
                    logMessage('Placed on hold by remote party.', window.level = logLevelType.WARNING);
                }
                break;
            }

            case 'm_remote_resume': {
                if (e.session === sipSessionCall) {
                    logMessage('Taken off hold by remote party.', window.level = logLevelType.WARNING);
                }
                break;
            }

            case 'm_bfcp_info': {
                if (e.session === sipSessionCall) {
                    logMessage(`BFCP Info: ${e.description}.`, window.level = logLevelType.INFO);
                }
                break;
            }

            case 'o_ect_trying': {
                if (e.session === sipSessionCall) {
                    logMessage('Call transfer in progress.', window.level = logLevelType.INFO);
                }
                break;
            }

            case 'o_ect_accepted': {
                if (e.session === sipSessionCall) {
                    logMessage('Call transfer accepted.', window.level = logLevelType.INFO);
                }
                break;
            }

            case 'o_ect_completed':
            case 'i_ect_completed': {
                if (e.session === sipSessionCall) {
                    logMessage('Call transfer completed.', window.level = logLevelType.INFO);
                    if (sipSessionTransferCall) {
                        sipSessionCall = sipSessionTransferCall;
                    }
                    sipSessionTransferCall = null;
                }
                break;
            }

            case 'o_ect_failed':
            case 'i_ect_failed': {
                if (e.session === sipSessionCall) {
                    logMessage('Call transfer failed.', window.level = logLevelType.ERROR);
                    window.btnTransfer.disabled = false;
                }
                break;
            }

            case 'o_ect_notify':
            case 'i_ect_notify': {
                let msgLevel;
                sipResponseCode = e.getSipResponseCode();

                if (e.session === sipSessionCall) {
                    if (sipResponseCode() >= 300) {
                        msgLevel = logLevelType.ERROR;
                        if (sipSessionCall.held) {
                            sipSessionCall.resume();
                        }
                    } else {
                        msgLevel = logLevelType.WARNING;
                    }
                    logMessage(`Call Transfer: ${sipResponseCode} ${e.description}.`, window.level = msgLevel);
                    window.btnTransfer.disabled = false;
                }
                break;
            }

            case 'i_ect_requested': {
                if (e.session === sipSessionCall) {
                    msg = `Do you accept call transfer to [${e.getTransferDestinationFriendlyName()}]?`; // FIXME
                    if (window.confirm(msg)) {
                        logMessage('Call transfer in progress.');
                        sipSessionCall.acceptTransfer();
                        break;
                    }
                    sipSessionCall.rejectTransfer();
                }
                break;
            }
        }
    };
}

function sipTransfer() {
    if (sipSessionCall) {
        const destination = prompt('Enter destination number', '*');
        if (!window.tsk_string_is_null_or_empty(destination)) {
            if (sipSessionCall.transfer(destination) != 0) {
                logMessage('Call transfer failed.', window.level = logLevelType.ERROR);
                return;
            }
            logMessage('Transfering the call.', window.level = logLevelType.INFO);
        }
    }
}

function sipToggleHoldResume(setSipStatus) {
    if (sipSessionCall) {
        let retVal;
        logMessage(sipSessionCall.held ? 'Resuming the call.' : 'Holding the call.', window.level = logLevelType.INFO);
        const wasHeld = sipSessionCall.held;
        retVal = sipSessionCall.held ? sipSessionCall.resume() : sipSessionCall.hold();
        if (retVal) {
            if (wasHeld) {
                setSipStatus(SIP_STATUS.CONNECTED);
            } else {
                setSipStatus(SIP_STATUS.PAUSED);
            }
            logMessage('Hold / Resume failed.', window.level = logLevelType.ERROR);
        }
    }
}

function sipToggleMute() {
    if (sipSessionCall) {
        let retVal;
        const mute = !sipSessionCall.flag_mute;
        logMessage(mute ? 'Muting the call.' : 'Unmuting the call.');

        retVal = sipSessionCall.mute('audio', mute);

        if (retVal != 0) {
            logMessage('Mute / Unmute failed.', window.level = logLevelType.ERROR);
            return;
        }
        sipSessionCall.flag_mute = mute;
        window.btnMute.textContent = mute ? 'Unmute' : 'Mute';
    }
}

function sipSendDTMF(c) {
    if (sipSessionCall && c) {
        if (sipSessionCall.dtmf(c) == 0) {
            try {
                window.dtmfTone.play();
            } catch (e) {
            }
        }
    }
}

function pauseQueue(setSipStatus) {
    sipCall('call-audio', PAUSE_NUMBER);
}

function unpauseQueue(setSipStatus) {
    sipCall('call-audio', UNPAUSE_NUMBER);
    setSipStatus(SIP_STATUS.CONNECTED);
}

function startRingTone() {
    try {
        window.ringtone.play();
    } catch (e) {
    }
}

function stopRingTone() {
    try {
        window.ringtone.pause();
    } catch (e) {
        console.warn(e);
    }
}

function startRingbackTone() {
    try {
        window.ringbacktone.play();
    } catch (e) {
    }
}

function stopRingbackTone() {
    try {
        window.ringbacktone.pause();
    } catch (e) {
    }
}

function logMessage(message, level = logLevelType.INFO) {
    console.log(message);
}

function showNotification(title, message) {
    // permission already asked for when we registered
    if (window.webkitNotifications && window.webkitNotifications.checkPermission() == 0) {
        if (notification) {
            notification.cancel();
        }

        // FIXME: sipml-34x39.png URL!
        notification = window.webkitNotifications.createNotification('images/sipml-34x39.png', title, message);
        notification.onclose = function () {
            notification = null;
        };
        notification.show();
    }
}

function AppWrapper(props) {
    const {user, userUseCases,  posStatutes} = props;
    const [sipStatus, setSipStatus] = useState(SIP_STATUS.DISCONNECTED);
    const [stateCalls, setCalls] = useState(callsFromLocaleStorage(JSON.parse(localStorage.getItem('sip_calls'))) || []);

    const simpleWindow = getQueryVariable('simple') === 'Y';

    useEffect(() => {
        if (user) {
            console.log(user);
            if (user.sipUserName && isComponentVisibleForUser(UC_SIP_PHONE, userUseCases) && !simpleWindow) {
                try {
                    window.SIPml.setDebugLevel('info');
                    remoteAudio = document.getElementById('audio-remote');
                    initializeSIPml5(setSipStatus, setCalls, user);
                } catch (err) {
                    console.log('SIP initialize error', err);
                }
            }
        }
    }, [user, userUseCases]);

    if (!user) {
        return (
            <Loading/>
        );
    }

    return (
        <App
            init={initializeSIPml5.bind(this, setSipStatus, setCalls, user)}
            sipStatus={sipStatus}
            posStatutes={posStatutes}
            userUseCases={userUseCases}
            calls={stateCalls}
            isSIP={isComponentVisibleForUser(UC_SIP_PHONE, userUseCases)}
            deleteCalls={() => {
                localStorage.setItem('sip_calls', JSON.stringify([]));
                setCalls([]);
                calls = [];
            }}
            pauseQueue={pauseQueue.bind(this, setSipStatus, setCalls)}
            unpauseQueue={unpauseQueue.bind(this, setSipStatus, setCalls)}
            sipToggleHoldResume={sipToggleHoldResume.bind(this, setSipStatus, setCalls)}
            sipCall={sipCall}
            remote={sipSessionCall && sipSessionCall.getRemoteFriendlyName() || 'unknown'}
            sipHangUp={sipHangUp.bind(this, setSipStatus, setCalls)}
            reject={reject.bind(this, setSipStatus, setCalls)}

        />
    );
}

AppWrapper.propTypes = {
    user: PropTypes.object,
};

AppWrapper.defaultProps = {
    user: null,
};

const mapStateToProps = (store) => ({
    user: store.authData.user,
    userUseCases: store.authData.userUseCases,
    posStatutes: store.parkingsData.posStatutes,

});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

const UC_SIP_PHONE = 'UC0049';


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppWrapper);
