import React from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {useTheme, makeStyles} from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Add} from "@material-ui/icons";

import {
    CustomSelect,
    CustomDateTimePicker,
    CustomButton,
    CustomSearchInput,
    CustomMultiSelect
} from 'components/elements';
import BigNumber from "bignumber.js";
import PMCSnackbar from 'components/common/Snackbar';

const useStyles = makeStyles((theme) => ({
    mb: {
        marginBottom: theme.spacing(1.125),
    },
    py12: {
        padding: theme.spacing(0, 1.5),
    },
    formGroup: {
        padding: theme.spacing(1, 0),
        display: 'flex',
        alignItems: 'flex-start',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    label: {
        width: theme.spacing(14),
        marginRight: theme.spacing(2),
        flexShrink: 0,
        fontSize: 14,
        lineHeight: '46px',
        color: '#A2A2A2',
        textAlign: 'right',
    },
    required: {
        fontWeight: 'bold',
    },
    rightLabel: {
        width: theme.spacing(14),
        marginRight: theme.spacing(2),
        flexShrink: 0,
        fontSize: 14,
        lineHeight: '46px',
        color: theme.palette.base[300],
        textAlign: 'right',
    },
    content: {
        flex: 1,
        overflow: 'hidden',

        '& .MuiSelect-select': {
            borderBottom: '1px solid #ADB0B8',
            fontSize: 16,
            color: theme.palette.base[800],
        },

        '& input': {
            height: 46,
            paddingLeft: theme.spacing(1.125),
            fontSize: 16,
            color: theme.palette.base[800],
            boxSizing: 'border-box',
        },

        '& svg': {
            width: 24,
            height: 24,
            fill: theme.palette.base[300],
            marginTop: 0,
        },

        '& > div': {
            display: 'flex',
            flex: 1,
            marginLeft: 0,
        },
    },
    flexWrapper: {
        width: '50%',
        padding: theme.spacing(0, 1.5),
        display: 'flex',
        alignItems: 'flex-start',
    },
    textField: {
        flex: 1,

        '& input': {
            height: 46,
            paddingLeft: theme.spacing(1.125),
            boxSizing: 'border-box',
        },

        '& textarea': {
            paddingLeft: theme.spacing(1.125),
        },
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    select: {
        '& .MuiSelect-select': {
            paddingLeft: theme.spacing(1.125),
            lineHeight: '25px',
        },
    },
    selectGroupe: {
        '& .MuiSelect-select': {
            paddingLeft: theme.spacing(1.125),
            lineHeight: '25px',
        },
        width: 250,
    },
    invalid: {
        '& .MuiSelect-select': {
            color: `${theme.palette.primary.red}`,
        },

        '& input': {
            color: `${theme.palette.primary.red}`,
        },

        '& textarea': {
            color: `${theme.palette.primary.red}`,
        },
    },
    overflowShown: {
        overflow: 'unset !important',
    },
    calculate: {
        height: 36,
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 200,
        },
    },
};

const getStyles = (value, items, theme) => ({
    fontWeight:
        items.indexOf(value) === -1
            ? theme.typography.fontWeightRegular
            : theme.typography.fontWeightMedium,
});

function CreateCardContent(props) {
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useTranslation();

    const {
        state,
        error,
        errorMessage,
        setErrorMessage,
        access,
        cardGroups,
        zoneItems,
        searchMenuData,
        handleSearch,
        searchMenuItemClick,
        handleChange,
        handleCalculateCredit,
        setMode,
        initSearchValue
    } = props;

    const isValid = () => {
        if (state.validTo && state.validFrom) {
            const validToTS = new Date(state.validTo).getTime();
            const validFromTS = new Date(state.validFrom).getTime();

            return validToTS >= validFromTS;
        }

        return true;
    };

    const handleCloseSnackbar = () => {
        setErrorMessage(null);
    }

    return (
        <>
            <Box className={classnames(classes.formGroup)}>
                <PMCSnackbar message={errorMessage} open={errorMessage} onClose={handleCloseSnackbar} severity='error'/>
                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classnames(classes.label, classes.required)}>
                        {t('CARD_NUMBER')}
                    </Typography>
                    <TextField
                        type="number"
                        autoFocus
                        required
                        InputProps={{
                            inputProps: {
                                max: 9223372036854775807, min: 1
                            }
                        }}
                        className={classes.textField}
                        hiddenLabel
                        value={state.cardNumber || ''}
                        onChange={
                            (e) => {
                                const max = new BigNumber("9223372036854775807");
                                const min = new BigNumber("1");
                                let value = new BigNumber(e.target.value);
                                if (value.gt(max) || min.gt(value)) {
                                    handleChange('cardNumber')(state.cardNumber);
                                } else {
                                    handleChange('cardNumber')(value.toFixed());
                                }
                            }
                        }
                        helperText={error && error.cardNumber && !error.duplicity
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : error && error.cardNumber && error.duplicity ?
                                <span className={classes.errorMessage}>{t('ALREADY_EXISTS_CARD')}</span> : null}
                    />
                </Box>

                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classes.rightLabel}>{t('LPN')}</Typography>
                    <TextField
                        className={classes.textField}
                        hiddenLabel
                        value={state.lpn || ''}
                        onChange={handleChange('lpn')}
                    />
                </Box>
            </Box>

            <Box className={classnames(classes.formGroup)}>
                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classnames(classes.label, classes.required)}>
                        {t('ACCESS')}
                    </Typography>
                    <Box className={classes.content}>
                        <CustomSelect
                            value={state.access}
                            items={access}
                            placeholder={t('ACCESS')}
                            className={classes.select}
                            handleChange={handleChange('access')}
                        />
                        {error && error.access && (
                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                        )}
                    </Box>
                </Box>

                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classes.rightLabel}>{t('OWNER')}</Typography>
                    <Box className={classnames(classes.content, classes.overflowShown)}>
                        <CustomSearchInput
                            placeholder={t('CARD_OWNER')}
                            menuData={searchMenuData}
                            handleChange={handleSearch}
                            menuItemClick={searchMenuItemClick}
                            initValue={initSearchValue}
                        />
                        {error && error.owner && (
                            <span className={classes.errorMessage}>{t('NOT_VALID_OWNER')}</span>
                        )}
                    </Box>

                    <IconButton onClick={() => {
                        setMode('OWNER')
                    }} className={classes.iconButton}>
                        <Add className={classes.icon}/>
                    </IconButton>
                    
                </Box>
            </Box>

            <Box className={classnames(classes.formGroup)}>
                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classnames(classes.label, classes.required)}>
                        {t('VALID_FROM')}
                    </Typography>
                    <Box
                        className={classnames(
                            classes.content,
                            !isValid() && classes.invalid,
                        )}
                    >
                        <CustomDateTimePicker
                            value={state.validFrom || null}
                            icon={<ArrowDropDown/>}
                            handleChange={handleChange('validFrom')}
                        />
                        {error && error.validFrom && (
                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                        )}
                    </Box>
                </Box>

                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classes.rightLabel}>{t('ZONE')}</Typography>
                    <Box className={classes.content}>
                        <CustomSelect
                            value={state.zone}
                            items={zoneItems}
                            placeholder={t('ZONE')}
                            className={classes.select}
                            handleChange={handleChange('zone')}
                        />
                    </Box>
                </Box>
            </Box>

            <Box className={classnames(classes.formGroup)}>
                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classnames(classes.label, classes.required)}>
                        {t('VALID_TO')}
                    </Typography>
                    <Box className={classnames(classes.content, !isValid() && classes.invalid)}>
                        <CustomDateTimePicker
                            value={state.validTo || null}
                            icon={<ArrowDropDown/>}
                            handleChange={handleChange('validTo')}
                        />
                        {error && error.validTo && (
                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                        )}
                    </Box>
                </Box>

                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classes.rightLabel}>{t('CARD_GROUP')}</Typography>
                    <Box className={classes.content}>
                        <CustomMultiSelect
                            className={classes.selectGroupe}
                            items={cardGroups}
                            label={t('CARD_GROUP')}
                            value={state.cardGroups}
                            color="black"
                            handleChange={handleChange('cardGroups')}
                            fromGroupe="true"
                        />
                        {/* <Select
              className={classes.select}
              multiple
              placeholder={t('CARD_GROUP')}
              value={state.cardGroups}
              onChange={handleChange('cardGroups')}
              MenuProps={MenuProps}
            >
              {cardGroups.map((cardGroup) => (
                <MenuItem
                  key={cardGroup.value}
                  value={cardGroup.value}
                  style={getStyles(cardGroup.value, state.cardGroups, theme)}
                >
                  {cardGroup.label}
                </MenuItem>
              ))}
            </Select> */}
                    </Box>
                </Box>
            </Box>

            <Box className={classnames(classes.formGroup)}>
                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classnames(classes.label, classes.required)}>
                        {t('CREDIT')}
                    </Typography>
                    <TextField
                        required
                        className={classes.textField}
                        hiddenLabel
                        value={state.credit || ''}
                        onChange={handleChange('credit')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <CustomButton
                                        className={classes.calculate}
                                        label={t('CALCULATE')}
                                        width={100}
                                        height={28}
                                        onClick={handleCalculateCredit}
                                    />
                                </InputAdornment>
                            ),
                        }}
                        helperText={error && error.credit
                            ? <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                            : null}
                    />
                </Box>

                <Box className={classes.flexWrapper}>
                    <Typography variant="button" className={classes.rightLabel}>{t('ZONE_RESERVATION')}</Typography>
                    <Box className={classes.content}>
                        <CustomSelect
                            value={state.zoneReservationId}
                            items={zoneItems}
                            placeholder={t('ZONE_RESERVATION')}
                            className={classes.select}
                            handleChange={handleChange('zoneReservationId')}
                        />
                    </Box>
                </Box>
            </Box>

            <Box className={classnames(classes.formGroup, classes.py12)}>
                <Typography variant="button" className={classes.label}>{t('COMMENT')}</Typography>
                <TextField
                    className={classes.textField}
                    multiline
                    rowsMax="2"
                    hiddenLabel
                    value={state.comment || ''}
                    onChange={handleChange('comment')}
                />
            </Box>
        </>
    );
}

CreateCardContent.propTypes = {
    state: PropTypes.object.isRequired,
    error: PropTypes.object,
    access: PropTypes.array.isRequired,
    cardGroups: PropTypes.array.isRequired,
    zoneItems: PropTypes.array.isRequired,
    searchMenuData: PropTypes.array.isRequired,
    handleSearch: PropTypes.func.isRequired,
    searchMenuItemClick: PropTypes.func.isRequired,
    handleChange: PropTypes.func.isRequired,
    handleCalculateCredit: PropTypes.func.isRequired,
};

CreateCardContent.defaultProps = {
    error: null,
};

export default CreateCardContent;
