import React, {useEffect, useCallback, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';

import {fetchOwnerDetail, updateOwner, newOwner} from 'redux/actions/owners';
import {CustomButton, CustomCheckbox, CustomSelect} from 'components/elements';
import {getCodeList, getErrorMessage} from 'utils';
import {ActionButton} from "../common";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PMCDialog from "../common/PMCDialog";

const useStyles = makeStyles((theme) => ({

    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    title: {
        background: '#F5894A',
        paddingRight: theme.spacing(2),
        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
            '& .MuiButtonBase-root': {
                color: theme.palette.base.white,
            },
            '& input + fieldset': {
                borderColor: theme.palette.base.white
            },
        },
    },
    formGroup: {
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',

        '&:last-child': {
            marginBottom: 0,
        },
    },
    wrapper: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
    },
    textField: {
        flex: 1,
    },
    label: {
        width: theme.spacing(12),
        marginRight: theme.spacing(3),
        color: '#A2A2A2',
        textAlign: 'right',
    },
    select: {
        flex: 1,

        '& .MuiSelect-select': {
            lineHeight: '20px',
            borderBottom: '1px solid #ADB0B8',
        },
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-start',
    },
    button: {
        width: 200,
        marginLeft: 20,
        marginRight: 20,
    },
}));

const OwnerDetails = ({
                          auth,
                          opened,
                          owner,
                          ownerDetail,
                          handleClose,
                          fetchOwnerDetail,
                          newOwner,
                          updateOwner,
                          user,
                          allCodeList
                      }) => {
    const classes = useStyles();
    const {t} = useTranslation();


    const isDisabled = auth.isRO;


    const groupItems = getCodeList(allCodeList, 'OWNER_GROUP');
    const groups = groupItems.length > 0 ? groupItems.map((item) => ({
        value: `group_${item.codeListItemId}`,
        label: item.code,
        key: `group_${item.codeListItemId}`,
    })) : [];

    const fetchOwnerDetailOnce = useCallback(() => {
        if (owner && owner.ownerId) {
            fetchOwnerDetail(owner.ownerId);
        } else {
            newOwner();
        }
    }, [fetchOwnerDetail, newOwner, owner]);


    useEffect(() => {
        fetchOwnerDetailOnce();
    }, [fetchOwnerDetailOnce]);

    const toState = useCallback((od) => ({
        id: od.ownerId,
        firstname: od.firstname || '',
        surname: od.surname || '',
        companyName: od.companyName || '',
        parkingSlot: od.parkingSlot || '',
        phone1: od.phone1 || '',
        phone2: od.phone2 || '',
        note: od.note || '',
        valid: od.valid,
        subjectId: od.subjectId
            || (user && user.subjectId),
        group: od.group || '',
    }), [user]);

    const [state, setState] = useState(toState(ownerDetail));

    useEffect(() => {
        setState(toState(ownerDetail));
    }, [ownerDetail, toState]);

    const handleSubmit = () => {
        updateOwner({
            firstname: state.firstname,
            surname: state.surname,
            companyName: state.companyName,
            parkingSlot: state.parkingSlot,
            phone1: state.phone1,
            phone2: state.phone2,
            note: state.note,
            valid: state.valid,
            subjectId: state.subjectId,
            group: state.group,
            ownerId: state.id,
        })
            .then(handleClose);
    };

    const handleChange = (field) => (event) => {
        switch (field) {
            case 'firstname':
            case 'surname':
            case 'companyName':
            case 'note':
                return setState({
                    ...state,
                    [field]: event.target.value,
                });
            case 'phone1':
            case 'phone2':
                return event.target.value.toString().length < 31
                    && setState({
                        ...state,
                        [field]: event.target.value,
                    });
            default:
                return setState({
                    ...state,
                    [field]: event,
                });
        }
    };

    return (

        <PMCDialog
            open={opened}
            title={`${t('CARD_OWNER')} ${owner.ownerId ? owner.ownerId : ''}`}
            onClose={handleClose}
            onSubmit={handleSubmit}
            headerColor="#008000"

        >

            <Box className={classes.formGroup}>
                <Box className={classes.wrapper}>
                    <Typography variant="button" className={classes.label}>{t('CARD_OWNER_NAME')}</Typography>
                    <TextField
                        autoFocus
                        required
                        className={classes.textField}
                        hiddenLabel
                        disabled={isDisabled}
                        value={state.firstname}
                        onChange={handleChange('firstname')}
                    />
                </Box>

                <Box className={classes.wrapper}>
                    <Typography variant="button" className={classes.label}>{t('CARD_OWNER_SURNAME')}</Typography>
                    <TextField
                        required
                        className={classes.textField}
                        hiddenLabel
                        disabled={isDisabled}
                        value={state.surname}
                        onChange={handleChange('surname')}
                    />
                </Box>
            </Box>

            <Box className={classes.formGroup}>
                <Box className={classes.wrapper}>
                    <Typography variant="button" className={classes.label}>{t('CARD_OWNER_PHONE_1')}</Typography>
                    <TextField
                        required
                        className={classes.textField}
                        hiddenLabel
                        disabled={isDisabled}
                        value={state.phone1}
                        type="number"
                        onChange={handleChange('phone1')}
                    />
                </Box>

                <Box className={classes.wrapper}>
                    <Typography variant="button" className={classes.label}>{t('CARD_OWNER_PHONE_2')}</Typography>
                    <TextField
                        required
                        className={classes.textField}
                        hiddenLabel
                        disabled={isDisabled}
                        value={state.phone2}
                        type="number"
                        onChange={handleChange('phone2')}
                    />
                </Box>
            </Box>

            <Box className={classes.formGroup}>
                <Box className={classes.wrapper}>
                    <Typography variant="button" className={classes.label}>{t('CARD_OWNER_GROUPS')}</Typography>
                    <CustomSelect
                        value={state.group || ''}
                        items={groups}
                        className={classes.select}
                        disabled={isDisabled}
                        placeholder={t('CARD_OWNER_GROUPS')}
                        handleChange={handleChange('group')}
                    />
                </Box>

                <Box className={classes.wrapper}>
                    <Typography variant="button" className={classes.label}>{t('CARD_OWNER_VALIDITY')}</Typography>
                    <CustomCheckbox
                        checked={state.valid}
                        disableCheck={isDisabled}
                        handleChange={handleChange('valid')}
                    />
                </Box>
            </Box>

            <Box className={classes.formGroup}>
                <Typography variant="button" className={classes.label}>{t('CARD_OWNER_NOTE')}</Typography>
                <TextField
                    className={classes.textField}
                    hiddenLabel
                    multiline
                    rowsMax="2"
                    inputProps={{
                        maxLength: 150,
                    }}
                    disabled={isDisabled}
                    value={state.note}
                    helperText={!isDisabled && (
                        <span>
                {`${150 - (state.note ? state.note.length : 0)} characters left`}
              </span>
                    )}
                    onChange={handleChange('note')}
                />
            </Box>
        </PMCDialog>
    );
}

OwnerDetails.propTypes = {
    auth: PropTypes.object.isRequired,
    opened: PropTypes.bool.isRequired,
    ownerDetail: PropTypes.object.isRequired,
    owner: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    fetchOwnerDetail: PropTypes.func.isRequired,
    newOwner: PropTypes.func.isRequired,
    updateOwner: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    allCodeList: PropTypes.array.isRequired,
};

const mapStateToProps = (store) => ({
    auth: store.authData,
    ownerDetail: store.ownersData.ownerDetail,
    allCodeList: store.codesData.allCodeList,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchOwnerDetail,
    updateOwner,
    newOwner,
}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OwnerDetails);
