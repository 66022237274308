import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Box from '@material-ui/core/Box';

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import {ArrowBackOutlined, ArrowForwardOutlined} from "@material-ui/icons";
import InputAdornment from "@material-ui/core/InputAdornment";
import SubjectsList from '../SubjectsList';
import IconButton from "@material-ui/core/IconButton";
import {isComponentVisibleForUser} from "../../../utils";
import Remove from '@material-ui/icons/Remove';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import ParkingSymbol from "../../common/ParkingSymbol";
import {SketchPicker} from "react-color";
import Add from "@material-ui/icons/Add";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {CustomSwitch} from "../../elements";
import {Tooltip} from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

const filter = createFilterOptions();


const useStyles = makeStyles((theme) => ({

    editor: {
        height: '100%',
        width: '100%',
        marginTop: '4px',
    },
    formFields: {
        height: '100%',
        width: '100%',
        flexDirection: 'row',
        display: 'flex',
        alignItems: 'flex-start',
        paddingTop: '50px',
    },
    editorWrapper: {
        height: '100%',
        width: '100%',
        padding: theme.spacing(3.25, 4, 4, 4),
    },
    description: {
        color: 'rgba(0, 0, 0, 0.54)',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: 1,
        letterSpacing: '0.06px',
    },
    errorMessage: {
        fontSize: 13,
        color: theme.palette.primary.red,
    },
    icon: {
        height: '20px',
        width: '20px',
    },
    iconButton: {
        backgroundColor: theme.palette.base[100],
        padding: theme.spacing(0.5, 0.5, 0.5, 0.5),
        marginBottom: '10px',
        marginRight: '5px',
    },
    root: {
        alignItems: 'flex-start',
    },
    addButton: {
        width: '100%',
        minWidth: '0px',
    },
    popover: {
        position: 'absolute',
        zIndex: '2',
    },
    cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    },
    connected: {
        color: theme.palette.base.white,
    },

}));

function ParkingDetail(props) {
    const classes = useStyles();

    const {
        userUseCases,
        handleChange,
        updatedParking,
        subjectValues,
        usersValues,
        error,
        isValid,
        handleUsers,
        subjects,
        mode,
        setMode,
        handleSubject,
        readOnly,
        getSubject,
        setSnackbarError,
        setSnackbarOpen,
        addKeyValue,
        removeKeyValue,
        updateKeyOrValue,
        handleNumberChange,
        isConnectionTypeSelected,
        connected,
    } = props;

    const {t} = useTranslation();

    const [displayColorPicker, setDisplayColorPicker] = useState(false);


    return (
        <div className={classes.formFields}>
            <form autoComplete='off'>
                <Box mb={2} height="100%">
                    {mode === 'COMMON' &&
                        <Grid container spacing={2}>
                            <Grid container
                                  spacing={2} sm={4} style={{marginLeft: "auto", marginRight: "auto"}}>


                                <Grid item sm={3}>

                                    <div>
                                        <div className={classes.swatch} onClick={() => setDisplayColorPicker(true)}>
                                            <ParkingSymbol
                                                color={updatedParking.color}
                                            />
                                        </div>
                                        {displayColorPicker ? <div className={classes.popover}>
                                            <div className={classes.cover}
                                                 onClick={() => setDisplayColorPicker(false)}/>
                                            <SketchPicker color={updatedParking.color}
                                                          onChange={handleChange('color', false)}/>
                                        </div> : null}

                                    </div>

                                </Grid>


                                <Grid item xs sm={9}>
                                    <TextField
                                        autoFocus
                                        helperText={error && error.parkingName
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        required
                                        disabled={readOnly}
                                        id="name"
                                        value={updatedParking.parkingName || ''}
                                        onChange={handleChange('parkingName', false)}
                                        label={t('NAME')}/>
                                </Grid>
                                <Grid item sm={12}>
                                    <Autocomplete
                                        value={getSubject(updatedParking.subjectId, subjectValues)}
                                        disabled={readOnly}
                                        disableClearable
                                        onChange={(event, newValue) => {
                                            handleSubject(newValue);
                                        }}
                                        forcePopupIcon={false}
                                        filterSelectedOptions
                                        options={subjectValues}
                                        getOptionLabel={(option) => {
                                            return option.label;
                                        }}
                                        style={{width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t('SUBJECT')} required fullWidth
                                                       helperText={error && error.subjectId
                                                           ? <span
                                                               className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                           : null}
                                                       InputProps={{
                                                           ...params.InputProps,
                                                           endAdornment: (
                                                               <InputAdornment position="end">
                                                                   {isComponentVisibleForUser(UC_MENU_SUBJECT_SEARCH, userUseCases) &&
                                                                       <IconButton onClick={() => {
                                                                           setMode('SUBJECT')
                                                                       }} className={classes.iconButton}>
                                                                           <ArrowForwardOutlined
                                                                               className={classes.icon}/>
                                                                       </IconButton>}
                                                               </InputAdornment>
                                                           ),
                                                       }}

                                            />
                                        )}
                                        size="small"

                                    />
                                </Grid>


                                <Grid item xs sm={12}>
                                    <TextField
                                        helperText={error && error.address
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        required
                                        disabled={readOnly}
                                        id="name"
                                        value={updatedParking.address || ''}
                                        onChange={handleChange('address', false)}
                                        label={t('ADDRESS')}/>
                                </Grid>

                                <Grid item xs sm={12}>
                                    <TextField
                                        helperText={error && error.email
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        required
                                        disabled={readOnly}
                                        id="email"
                                        value={updatedParking.email || ''}
                                        onChange={handleChange('email', false)}
                                        label={t('EMAIL')}/>
                                </Grid>


                                <Grid item xs sm={6}>
                                    <TextField
                                        helperText={error && error.phone
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        required
                                        disabled={readOnly}
                                        id="phone"
                                        value={updatedParking.phone || ''}
                                        onChange={handleChange('phone', false)}
                                        label={t('PHONE')}/>
                                </Grid>

                                <Grid item xs sm={6}>
                                    <TextField
                                        helperText={error && error.helpdeskPhone
                                            ?
                                            <span className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                            : null}
                                        fullWidth
                                        required
                                        disabled={readOnly}
                                        id="helpdeskPhone"
                                        value={updatedParking.helpdeskPhone || ''}
                                        onChange={handleChange('helpdeskPhone', false)}
                                        label={t('HELP_DESK')}/>
                                </Grid>


                                <Grid item sm={12}>
                                    <Autocomplete
                                        multiple 
                                        disableCloseOnSelect
                                        limitTags={5}
                                        value={updatedParking && updatedParking.users ? updatedParking.users.map(a => {
                                                const p = usersValues && usersValues.find(b => b.value === a);
                                                if (p) {
                                                    return p;
                                                } else {
                                                    return {value: a, label: 'NOT FOUND'}
                                                }
                                            }
                                        ) : []}
                                        onChange={(event, newValue) => {
                                            handleUsers(newValue);
                                        }}
                                        filterSelectedOptions
                                        id="users"
                                        options={usersValues}
                                        getOptionLabel={(option) => {
                                            return option.label;
                                        }}
                                        style={{width: '100%'}}
                                        renderInput={(params) => (
                                            <TextField {...params} label={t('USER')} required fullWidth
                                                       helperText={error && error.users
                                                           ? <span
                                                               className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                           : null}

                                            />
                                        )}
                                        size="small"

                                    />
                                </Grid>

                            </Grid>
                            <Grid container alignContent="flex-start" sm={3} spacing={2}
                                  style={{marginLeft: "auto", marginRight: "auto"}}>


                                {updatedParking.values && updatedParking.values.length > 0 ? updatedParking.values.map(
                                        (value, index) => {
                                            return (
                                                <Grid container style={{width: '100%'}}>
                                                    <Grid item xs sm={5}>
                                                        <TextField
                                                            helperText={error && error.values && error.values[index] && error.values[index].parkingKey
                                                                ?
                                                                <span
                                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                                : null}
                                                            fullWidth
                                                            disabled={readOnly}
                                                            id={`currentKey${index}`}
                                                            value={value.parkingKey || ''}
                                                            onChange={(event) => updateKeyOrValue(index, event.target.value, 'parkingKey')}
                                                            label={t('KEY')}/>
                                                    </Grid>
                                                    <Grid item xs sm={5}>
                                                        <TextField
                                                            helperText={error && error.values && error.values[index] && error.values[index].parkingValue
                                                                ?
                                                                <span
                                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                                : null}

                                                            fullWidth
                                                            disabled={readOnly}
                                                            id={`currentValue${index}`}
                                                            value={value.parkingValue || ''}
                                                            onChange={(event) => updateKeyOrValue(index, event.target.value, 'parkingValue')}
                                                            label={t('VALUE')}/>
                                                    </Grid>
                                                    <Grid item xs sm={2}>
                                                        <IconButton onClick={() => {
                                                            removeKeyValue(index)
                                                        }}>
                                                            <Remove/>
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>
                                            );
                                        }
                                    ) :
                                    <Grid container style={{width: '100%'}}>


                                        <Grid item xs sm={6}>
                                            <Typography variant="button">{t('KEY')}</Typography>
                                            <TextField
                                                style={{
                                                    visibility:
                                                        'hidden'
                                                }}
                                                fullWidth
                                                label={t('KEY')}/>
                                        </Grid>
                                        <Grid item xs sm={6}>
                                            <Typography variant="button">{t('VALUE')}</Typography>
                                            <TextField
                                                style={{
                                                    visibility:
                                                        'hidden'
                                                }}

                                                fullWidth
                                                label={t('KEY')}/>
                                        </Grid>
                                    </Grid>

                                }

                                <Grid item xs sm={10}>
                                </Grid>
                                <Grid item xs sm={2}>

                                    <IconButton
                                        onClick={() => {
                                            addKeyValue();
                                        }}>
                                        <Add/>
                                    </IconButton>
                                </Grid>
                            </Grid>

                            <Grid container alignItems="stretch" spacing={2} sm={4}
                                  style={{marginLeft: "auto", marginRight: "auto"}}>
                                <Grid item xs sm={6}>
                                    <FormControl fullWidth disabled={connected}>
                                        <InputLabel id="parking-label">
                                            {t('PROTOCOL')}
                                        </InputLabel>
                                        <Select
                                            autoWidth
                                            disabled={readOnly}
                                            value={updatedParking.connectionType || ''}
                                            id="connectionType"
                                            onChange={handleChange('connectionType', true)}
                                        >
                                            <MenuItem value=''>-</MenuItem>
                                            <MenuItem value='GMTP'>GMTP</MenuItem>
                                            <MenuItem value='MQ'>MQ</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {(updatedParking.connectionType === 'GMTP' || updatedParking.connectionType === 'MQ') &&
                                    <Grid item xs sm={6}>
                                        <TextField
                                            type="number"
                                            helperText={error && error.gmtpIdent
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="gmtpIdent"
                                            value={updatedParking.gmtpIdent!==null && updatedParking.gmtpIdent!==undefined ? updatedParking.gmtpIdent : ''}
                                            onChange={handleNumberChange('gmtpIdent', true)}
                                            label={t('GMPT_ID')}/>
                                            
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.applicationName
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="applicationName"
                                            value={updatedParking.applicationName || ''}
                                            onChange={handleChange('applicationName', true)}
                                            label={t('APPLICATION_NAME')}/>

                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.clientIp
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="clientIp"
                                            value={updatedParking.clientIp || ''}
                                            onChange={handleChange('clientIp', true)}
                                            label={t('CLIENT_IP')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&
                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.logServerIp
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="logServerIp"
                                            value={updatedParking.logServerIp || ''}
                                            onChange={handleChange('logServerIp', true)}
                                            label={t('LOG_SERVER_IP')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.clientPort
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            type="number"
                                            disabled={connected}
                                            id="clientPort"
                                            value={updatedParking.clientPort || ''}
                                            onChange={handleNumberChange('clientPort', true)}
                                            label={t('CLIENT_PORT')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.logServerPort
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            type="number"
                                            disabled={connected}
                                            id="logServerPort"
                                            value={updatedParking.logServerPort || ''}
                                            onChange={handleNumberChange('logServerPort', true)}
                                            label={t('LOG_SERVER_PORT')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            type="number"
                                            helperText={error && error.timeOut
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="timeOut"
                                            value={updatedParking.timeOut || ''}
                                            onChange={handleNumberChange('timeOut', true)}
                                            label={t('TIMEOUT')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.userName
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="userName"
                                            value={updatedParking.userName || ''}
                                            onChange={handleChange('userName', true)}
                                            label={t('USERNAME')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.serverIp
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="serverIp"
                                            value={updatedParking.serverIp || ''}
                                            onChange={handleChange('serverIp', true)}
                                            label={t('SERVER_IP')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.userPassword
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            type="password"
                                            required={isConnectionTypeSelected()}
                                            disabled={connected}
                                            id="userPassword"
                                            value={updatedParking.userPassword || ''}
                                            onChange={handleChange('userPassword', true)}
                                            label={t('PASSWORD')}/>
                                    </Grid>
                                }
                                {updatedParking.connectionType === 'GMTP' &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            helperText={error && error.serverPort
                                                ?
                                                <span
                                                    className={classes.errorMessage}>{t('THIS_FIELD_IS_REQUIRED')}</span>
                                                : null}
                                            fullWidth
                                            required={isConnectionTypeSelected()}
                                            type="number"
                                            disabled={connected}
                                            id="serverPort"
                                            value={updatedParking.serverPort || ''}
                                            onChange={handleNumberChange('serverPort', true)}
                                            label={t('SERVER_PORT')}/>
                                    </Grid>
                                }
                                {(updatedParking.connectionType === 'GMTP' || updatedParking.connectionType === 'MQ') &&

                                    <Grid item xs sm={6}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            disabled={connected}
                                            id="sipPhoneNumber"
                                            value={updatedParking.sipPhoneNumber || ''}
                                            onChange={handleNumberChange('sipPhoneNumber', true)}
                                            label={t('SIP_PHONE_NUMBER')}/>
                                    </Grid>
                                }
                                {(updatedParking.connectionType === 'GMTP' || updatedParking.connectionType === 'MQ') &&
                                    <Grid item xs sm={6}>
                                        <TextField
                                            fullWidth
                                            disabled={connected}
                                            id="ringTone"
                                            value={updatedParking.ringTone || ''}
                                            onChange={handleChange('ringTone', true)}
                                            label={t('RING_TONE')}/>
                                    </Grid>
                                }
                                <Grid item xs sm={12}>
                                    <Tooltip title={t('AUTO_CONNECT_DESCRIPTION')}>
                                        <FormControlLabel
                                            control={<CustomSwitch
                                                disabled={connected}
                                                checked={updatedParking.connect}
                                                color="secondary"
                                                handleSwitch={handleChange('connect', true)}/>
                                            }
                                            label={<span
                                                style={{
                                                    fontWeight: 400,
                                                    fontSize: '14px',
                                                    color: 'rgba(0, 0, 0, 0.54)',
                                                }}>{t('AUTO_CONNECT')}</span>}

                                        />
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>}
                </Box>
            </form>
            {mode !== 'COMMON' &&
                <IconButton onClick={() => {
                    setMode('COMMON')
                }} className={classes.iconButton}>
                    <ArrowBackOutlined className={classes.icon}/>
                </IconButton>
            }
            {mode === 'SUBJECT' && <SubjectsList
                userUseCases={userUseCases}
                setSnackbarError={setSnackbarError}
                setSnackbarOpen={setSnackbarOpen}
                setMode={setMode} handleSubjectChange={handleSubject}/>}

        </div>
    )
}

ParkingDetail.propTypes = {};

ParkingDetail.defaultProps = {};

const UC_MENU_BRANCH_SEARCH = 'UC0250';
const UC_MENU_SUBJECT_SEARCH = 'UC0200';


const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ParkingDetail);


