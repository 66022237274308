import React, {useState,} from 'react';
import {useTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {makeStyles} from '@material-ui/core/styles';

import {CustomTable, Loading} from 'components/elements';
import {connect} from 'react-redux';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import Close from '@material-ui/icons/Close';
import {deleteParking, restart} from 'redux/actions/parkings';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import {ActionButton, ParkingName} from "../../../components/common";
import {
    getErrorMessage,
    isComponentEnabledForUser,
    isComponentVisibleForUser,
    renderConnectionStatus
} from "../../../utils";
import ParkingSymbol from "../../../components/common/ParkingSymbol";
import Typography from "@material-ui/core/Typography";
import RefreshIcon from '@material-ui/icons/Refresh';
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import TuneIcon from '@material-ui/icons/Tune';
import OKStateIcon from "@material-ui/icons/CheckCircleOutline";
import NotConnectedStateIcon from "@material-ui/icons/CloudOff";
import RestartDisabledIcon from '@material-ui/icons/SyncDisabled';
import NotSetIcon from '@material-ui/icons/ContactSupport';
import CommunicationErrorIcon from '@material-ui/icons/Error';

import {
    UC_ST_CARD_BLOCK,
    UC_ST_CARD_DEACTIVATE
} from "../../../components/modals/CardDetails/CardParkingDetail/CardJournals";
import {UC_PARKING_EDIT} from "../../../components/modals/Management";
import {Chip, Tooltip} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    container: {
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        paddingBottom: 100,
        paddingTop: 20,

    },
    navIcon: {
        fontSize: 11,
    },
    actionIcon: {
        marginTop: 1,
        fontSize: 20,
    },
    parkingCard: {
        width: 384,
        height: 230,
        margin: theme.spacing(2.5),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: theme.palette.base[100],
        borderStyle: 'solid',
        borderWidth: '2px',
        cursor: 'pointer',
        borderColor: theme.palette.base[200],
        boxShadow: '10px 10px 8px #EEEEEE',
    },
    root: {
        width: 380,
        height: 40,
        background: theme.palette.base[100],
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    row1: {
        width: 380,
        height: 60,
        fontSize: 12,
        padding: 5,
        marginTop: -10,
        marginBottom: 10,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    symbol: {
        top: 5,
    },
    connected: {
        color: theme.palette.base.white,
    },
    communicationError: {
        color: theme.palette.primary.red,
    },
    row2: {
        width: 380,
        height: 40,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    row3: {
        width: 390,
        height: 40,
        fontSize: 22,
        paddingLeft: 5,
        paddingRight: 5,
        paddingBottom: 5,
        marginTop: 10,
        justifyContent: 'space-between',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    rowColumn: {
        display: 'flex',
        flexDirection: 'column',
        width: 130,
    },
    rowColumnFull: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    green: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    blue: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    orange: {
        backgroundColor: theme.palette.primary.yellow,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    disabled: {
        backgroundImage: 'linear-gradient(135deg, #FFFFFF00 25%, #FFFFFF3F 25%, #FFFFFF3F 50%, #FFFFFF00 50%, #FFFFFF00 75%, #FFFFFF3F 75%, #FFFFFF3F 100%)',
        backgroundSize: '20px 20px',
    },
    red: {
        backgroundColor: theme.palette.primary.red,
        color: theme.palette.base.white,
        minWidth: '50%',
    },
    empty: {
        width: '100%',
        height: 416,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
    },
    containerModal: {
        '& .MuiDialog-paper': {
            width: 834,
            maxWidth: 'unset',
            height: 'fit-content',
            padding: theme.spacing(4.25, 3.25),
            boxShadow: theme.palette.shadow.main,
        },
    },
    title: {
        color: theme.palette.base[700],
        marginTop: 20,
        '& .MuiTypography-root': {
            fontSize: 20,
        },
    },
    label: {
        color: theme.palette.base[400],
        fontSize: 16,
        textAlign: 'center',
    },
    value: {
        color: theme.palette.base[700],
        fontSize: 18,
        minHeight: 25,
        textAlign: 'center',
    },
    mr: {
        marginRight: theme.spacing(2.5),
    },
    actions: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    stateIcon: {
        paddingRight: 3,
        fontSize: 11,
    }

}));

const tableOptions = {
    headerStyle: {
        padding: '15px 8px 15px 34px',
        lineHeight: '12px',
        color: '#1B1B28',
        textAlign: 'center',
    },
    paging: false
};
const cellStyle = {
    paddingLeft: 4,
    paddingRight: 4,
    lineHeight: '19px',
    color: '#1B1B28',
    textAlign: 'center',
};

function Parkings(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        parkings,
        userUseCases,
        isLoading,
        handleParkingDetailBasic,
        handleParkingDetail,
        deleteParking,
        refresh,
        setDeletionSnackbarOpen,
        setDeletionSnackbarError,
        handleRestart,
        table,
        sseParkings,
    } = props;


    const [confirmModalOpened, setConfirmModalOpened] = useState(false);
    const [parkingToDelete, setParkingToDelete] = useState();


    const columns = [
        {
            title: t('PARKING_NAME'),
            field: 'parkingId',
            headerStyle: {textAlign: 'left', paddingLeft: 5},
            cellStyle,
            render: (rowData) => {
                const parking = parkings.find((p) => p.parkingId === rowData.parkingId);
                return (
                    <div className={classes.wrapper}>
                        <ParkingName parking={parking}/>
                    </div>
                );
            },
            customSort: (a, b) => a.parkingName.localeCompare(b.parkingName),

        },
        {
            title: t('SUBJECT'),
            field: 'subjectName',
            cellStyle,
            render: (rowData) => (
                rowData.subjectName
            ),
        },
        {
            title: t('PROTOCOL'),
            field: 'connectionType',
            cellStyle,
            render: (rowData) => {
                const sseParking = sseParkings.find(a => a.parkingid === rowData.parkingId);
                renderConnectionStatus(rowData.connectionType, sseParking ? sseParking.communicationStatus : rowData.communicationStatus, classes, t)

            },
        },
        {
            title: t('GMPT_ID'),
            field: 'gmtpIdent',
            cellStyle,
            render: (rowData) => (
                rowData.gmtpIdent
            ),
        },
        {
            title: t('HOST_COUNT'),
            field: 'hostCount',
            cellStyle,
            render: (rowData) => (
                rowData.hostCount
            ),
        },
        {
            title: '',
            field: 'action',
            sorting: false,
            cellStyle: {
                ...cellStyle,
                padding: '12px 4px',
            },
            headerStyle: {
                padding: '18px 34px',
            },
            render: (rowData) => (
                <div className={classes.actions}>
                    {isComponentVisibleForUser(UC_PARKING_EDIT, userUseCases) && (
                        <ActionButton
                            disabled={!isComponentEnabledForUser(UC_PARKING_EDIT, userUseCases)}
                            className={classes.mr}
                            action="edit"
                            handleClick={() => {
                                handleParkingDetailBasic(rowData, false)
                            }}
                        />
                    )}
                    {isComponentVisibleForUser(UC_PARKING_EDIT, userUseCases) && (
                        <ActionButton
                            disabled={!isComponentEnabledForUser(UC_PARKING_EDIT, userUseCases) || !rowData.connectionType}
                            className={!rowData.connectionType ? `${classes.mr} ${classes.disabled}` : classes.mr}
                            action="wizard"
                            handleClick={() => handleParkingDetail(rowData, false)}
                        />
                    )}
                    {isComponentVisibleForUser(UC_PARKING_RESTART, userUseCases) && (<ActionButton
                        disabled={!isComponentEnabledForUser(UC_PARKING_RESTART, userUseCases) || !rowData.connect || !rowData.connectionType}
                        className={(!rowData.connect || !rowData.connectionType) ? classes.disabled : ''}
                        action="restart"
                        handleClick={() => handleRestart(rowData)}
                    />)
                    }
                </div>
            ),
        },

    ];


    const EmptyPage = () => (isLoading ? (
        <div className={classes.empty}>
            <Loading/>
        </div>
    ) : (
        <div className={classes.empty}>
        </div>
    ));


    const deleteParkingInner = (parking) => {
        deleteParking(parking).then(
            () => {
                setParkingToDelete(null);
                setConfirmModalOpened(false);
                refresh()
            }
        ).catch((error) => {
            setDeletionSnackbarOpen(true);
            setDeletionSnackbarError(getErrorMessage(error, t));
        });
    }

    return (
        <div className={classes.container}>


            {confirmModalOpened
                && (
                    <Dialog
                        className={classes.containerModal}
                        open={confirmModalOpened}
                        onClose={() => {
                            setConfirmModalOpened(false);
                            setParkingToDelete(null);
                        }}
                    >
                        <DialogTitle className={classes.title}>
                            {t('REMOVE_PARKING_TITLE')}
                        </DialogTitle>

                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                {t('CONFIRM_REMOVE_PARKING', {
                                    parkingName: parkingToDelete.parkingName,

                                })}
                            </DialogContentText>
                        </DialogContent>

                        <DialogActions className={classes.actions}>
                            <ActionButton
                                action="confirm_remove_user"
                                handleClick={
                                    () => {
                                        deleteParkingInner(parkingToDelete);
                                    }}
                            />
                            <ActionButton
                                action="cancel"
                                handleClick={() => {
                                    setConfirmModalOpened(false);
                                    setParkingToDelete(null);
                                }}
                            />
                        </DialogActions>
                    </Dialog>
                )}


            {parkings.length > 0 ? (
                    table ? <CustomTable
                        title=""
                        columns={columns}
                        data={parkings}
                        options={tableOptions}
                        isLoading={isLoading}
                        loadAllData={false}
                    /> : parkings.map((parking, index) => {
                        const sseParking = sseParkings.find(a => a.parkingId === parking.parkingId);
                        return (<div
                                className={classes.parkingCard}>
                                <div className={classes.row1}>
                                    <ParkingSymbol
                                        className={classes.symbol}
                                        color={parking.color}
                                    />
                                    <div className={classes.title}>
                                        <Typography variant="h4">{parking.parkingName}</Typography>
                                    </div>
                                    <div className={classes.actionIcon}>
                                        <IconButton onClick={() => {
                                            handleParkingDetailBasic(parking, false)
                                        }}>
                                            <EditIcon/>
                                        </IconButton>
                                    </div>
                                </div>


                                <div className={classes.row2}>
                                    <div className={classes.rowColumnFull}>
                                        <div className={classes.value}>{parking.subjectName}</div>
                                    </div>
                                </div>

                                <div className={classes.row3}>
                                    <div className={classes.rowColumn}>
                                        <div className={classes.label}></div>
                                        <div className={classes.value}>
                                            {renderConnectionStatus(parking.connectionType, sseParking ? sseParking.communicationStatus : parking.communicationStatus, classes, t)}
                                        </div>
                                    </div>
                                    <div className={classes.rowColumn}>
                                        <div className={classes.label}>{t('GMPT_ID')}</div>
                                        <div className={classes.value}>{parking.gmtpIdent}</div>
                                    </div>
                                    <div className={classes.rowColumn}>
                                        <div className={classes.label}>{t('HOST_COUNT')}</div>
                                        <div className={classes.value}>{parking.hostCount}</div>
                                    </div>
                                </div>

                                <BottomNavigation
                                    className={classes.root}
                                >

                                    {isComponentVisibleForUser(UC_PARKING_EDIT, userUseCases) &&

                                    !parking.connectionType ?
                                        <Tooltip title={t('EXTENDED_EDITATION_DISABLED')}>
                                            <BottomNavigationAction className={`${classes.blue} ${classes.disabled}`}
                                                                    label="Close"
                                                                    icon={<TuneIcon className={classes.actionIcon}/>}/>
                                        </Tooltip>
                                        :
                                        <BottomNavigationAction onClick={() => {
                                            handleParkingDetail(parking, false)
                                        }}
                                                                className={classes.blue}
                                                                label="Update"
                                                                icon={<TuneIcon className={classes.actionIcon}/>}/>
                                    }
                                    {isComponentVisibleForUser(
                                        UC_PARKING_RESTART, userUseCases) &&
                                    (parking.connect && parking.connectionType) ?
                                        <BottomNavigationAction className={classes.orange} label="Close"
                                                                onClick={() => {
                                                                    handleRestart(parking);
                                                                }}
                                                                icon={<RefreshIcon className={classes.actionIcon}/>}/>
                                        :
                                        <Tooltip
                                            title={`${t('RESTART_DISABLED')}${(!parking.connect) ? '.' + t('RESTART_CONNECTION_FALSE') : ''}${(!parking.connectionType) ? '. ' + t('RESTART_PROTOCOL_NOT_SET') : ''}`}>
                                            <BottomNavigationAction className={`${classes.orange} ${classes.disabled}`}
                                                                    label="Close"
                                                                    icon={<RestartDisabledIcon
                                                                        className={classes.actionIcon}/>}/>
                                        </Tooltip>

                                    }
                                </BottomNavigation>
                            </div>
                        )
                    }))
                :
                <EmptyPage/>
            }
        </div>
    );
}

Parkings.propTypes = {
    parkings: PropTypes.array.isRequired,

};


//const UC_PARKING_DELETE = 'UC0620';
const UC_PARKING_RESTART = 'UC0620';


Parkings.defaultProps = {};

const mapStateToProps = (store) => ({});

const mapDispatchToProps = (dispatch) => bindActionCreators({deleteParking}, dispatch);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Parkings);
