import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

import { ParkingSymbol } from 'components/common';
import { CustomTabs } from 'components/elements';
import { DisappointedIcon } from 'assets/Icons';
import { unRegistrePhoto } from 'redux/actions/photo';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LocalParkingEvents from './LocalParkingEvents';
import LocalParkingHosts from './LocalParkingHosts';
import ParkingZones from './ParkingZones';
import MobileOperatorTasks from './MobileOperatorTasks';
import ParkingCards from './ParkingCards';
import {isComponentVisibleForUser} from 'utils';


const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(7.5),
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    marginLeft: theme.spacing(1),
    marginBottom: theme.spacing(3.75),
    textTransform: 'uppercase',
  },
  symbol: {
    marginBottom: theme.spacing(3.75),
  },
  disconnectedContent: {
    width: '100%',
    height: '416px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.default,
    color: theme.palette.base[300],
    textAlign: 'center',
  },
  disconnectedIcon: {
    marginBottom: theme.spacing(1.5),
  },
}));

function LocalParking(props) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    selected,
    selectedHostName,
    setSelectedHostName,
    handleTaskCreateOpen,
    handleTaskDetailOpen,
    handleCameraDetailOpen,
    handleEditZoneOpen,
    handleCardDetailOpen,
    handleCreateCardOpen,
    handleEditCardOpen,
    handleBlockCardOpen,
    handleConfirmCardOpen,
    events,
    sseParkingHosts,
    occupancy,
    withDetail,
    unRegistrePhoto,
    sipCall,
    sipStatus,
    calls,
    userUseCases,
  } = props;

  const [activeTab, setActiveTab] = useState(0);

  const getHostByHostName = (hostName) => {
    if (hostName && sseParkingHosts) {
      const s = sseParkingHosts.filter((s) => s.hostName === hostName);
      if (s && s.length > 0) {
        return s[0];
      }
    }
  };

  const handleChangeTab = (value) => {
    if (value !== 0) {
      if (activeTab === 0) {
        const host = getHostByHostName(selectedHostName);
        if (host) {
          unRegistrePhoto(host.parkingHostId);
        }
      }
    }
    setActiveTab(value);
  };

  const checkActivated = (value) => {
    if (activeTab === -1) return true;
    return value === activeTab;
  };

  useEffect(() => {
    if (selectedHostName && isComponentVisibleForUser(UC_TAB_HOSTS, userUseCases)) {
      setActiveTab(0);
    }
  }, [selectedHostName]);

  const handleHostClick = (value) => {
    setSelectedHostName(value);
    setActiveTab(0);
  };

  const handleCardDetailOpenInner = (value) => {
    if (value.hostName) {
      setSelectedHostName(value.hostName);
      setActiveTab(0);
    }

    handleCardDetailOpen(value);
  };

  const tabs = [
  ];
  const tabContents = [
  ];

  if (isComponentVisibleForUser(UC_TAB_HOSTS, userUseCases)) {
    tabs.push(t('LOCAL_PARKING_HOSTS'));
    tabContents.push(<LocalParkingHosts
        parking={selected}
        active={checkActivated(tabContents.length)}
        selectedHostName={selectedHostName}
        setSelectedHostName={setSelectedHostName}
        handleCreateTaskOpen={handleTaskCreateOpen}
        handleCameraDetailOpen={handleCameraDetailOpen}
        withDetail={withDetail}
        sseParkingHosts={sseParkingHosts}
        sipCall={sipCall}
        sipStatus={sipStatus}
        calls={calls}
        userUseCases={userUseCases}
    />);
  };
  if (isComponentVisibleForUser(UC_TAB_EVENTS, userUseCases)) {
    tabs.push(t('LOCAL_PARKING_EVENTS'));
    tabContents.push(   <LocalParkingEvents
        parking={selected}
        active={checkActivated(tabContents.length)}
        handleCreateOpen={handleTaskCreateOpen}
        handleHostClick={handleHostClick}
        handleCardDetailOpen={handleCardDetailOpenInner}
        events={events}
        userUseCases={userUseCases}
    />);
  };
  if (isComponentVisibleForUser(UC_TAB_ZONES, userUseCases)) {
    tabs.push(t('PARKING_ZONES'));
    tabContents.push(     <ParkingZones
        parking={selected}
        active={checkActivated(tabContents.length)}
        handleEditZoneOpen={handleEditZoneOpen}
        occupancy={occupancy}
        userUseCases={userUseCases}
    />);
  };
  if (isComponentVisibleForUser(UC_TAB_LT_CARDS, userUseCases)) {
    tabs.push(t('LONG_TERM_CARDS'));
    tabContents.push(    <ParkingCards
        cardType="LT"
        parking={selected}
        active={checkActivated(tabContents.length)}
        handleCardDetailOpen={handleCardDetailOpenInner}
        handleCreateCardOpen={handleCreateCardOpen}
        handleEditCardOpen={handleEditCardOpen}
        handleBlockCardOpen={handleBlockCardOpen}
        handleConfirmCardOpen={handleConfirmCardOpen}
        userUseCases={userUseCases}
    />);
  };
  if (isComponentVisibleForUser(UC_TAB_ST_CARDS, userUseCases)) {
    tabs.push(t('SHORT_TERM_CARDS'));
    tabContents.push(  <ParkingCards
        cardType="ST"
        parking={selected}
        active={checkActivated(tabContents.length)}
        handleCardDetailOpen={handleCardDetailOpenInner}
        handleCreateCardOpen={handleCreateCardOpen}
        handleEditCardOpen={handleEditCardOpen}
        handleBlockCardOpen={handleBlockCardOpen}
        handleConfirmCardOpen={handleConfirmCardOpen}
        userUseCases={userUseCases}
    />);
  };
  if (isComponentVisibleForUser(UC_TAB_TASKS, userUseCases)) {
    tabs.push(t('MOBILE_OPERATOR_TASKS'));
    tabContents.push(<MobileOperatorTasks
        parking={selected}
        active={checkActivated(tabContents.length)}
        handleTaskDetailOpen={handleTaskDetailOpen}
        handleCreateOpen={handleTaskCreateOpen}
        userUseCases={userUseCases}
    />);
  };

  const notConnectedTabContent = [
    <div className={classes.disconnectedContent}>
      <DisappointedIcon className={classes.disconnectedIcon} />
      <Typography variant="subtitle1">
        {t('PARKING_DATA_IS_NOT_AVAILABLE_A_THE_MOMENT_DUE_TO_LOST_OF_CONNECTION_WITH_PARKING')}
      </Typography>
    </div>,
  ];


  return (
    <div className={classes.container}>
      <div className={classes.titleWrapper}>
        <ParkingSymbol
          className={classes.symbol}
          variant="h4"
          color={selected.color}
        />
        <Typography variant="h4" className={classes.title}>
          {selected.parkingName}
        </Typography>
      </div>

      <CustomTabs
        activeTab={activeTab}
        tabs={tabs}
        tabContents={selected.isConnected ? tabContents : notConnectedTabContent}
        disabled={!selected.isConnected}
        variant="fullWidth"
        handleChangeTab={handleChangeTab}
      />
    </div>
  );
}

LocalParking.propTypes = {
  selected: PropTypes.object.isRequired,
  handleTaskCreateOpen: PropTypes.func.isRequired,
  handleTaskDetailOpen: PropTypes.func.isRequired,
  handleCameraDetailOpen: PropTypes.func.isRequired,
  handleEditZoneOpen: PropTypes.func.isRequired,
  handleCardDetailOpen: PropTypes.func.isRequired,
  handleCreateCardOpen: PropTypes.func.isRequired,
  handleEditCardOpen: PropTypes.func.isRequired,
  handleBlockCardOpen: PropTypes.func.isRequired,
  handleConfirmCardOpen: PropTypes.func.isRequired,
  setSelectedHostName: PropTypes.func.isRequired,
  selectedHostName: PropTypes.string,
  events: PropTypes.array.isRequired,
  sseParkingHosts: PropTypes.array.isRequired,
  occupancy: PropTypes.array.isRequired,
};

LocalParking.defaultProps = {
  selectedHostName: null,
};

const mapStateToProps = (store) => ({
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  unRegistrePhoto,
}, dispatch);

const UC_TAB_HOSTS = 'UC0005';
const UC_TAB_EVENTS = 'UC0015';
const UC_TAB_ZONES = 'UC0018';
const UC_TAB_LT_CARDS = 'UC0020';
const UC_TAB_ST_CARDS = 'UC0029';
const UC_TAB_TASKS = 'UC0035';
const UC_TAB_OPERATORS = 'UC0039';


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LocalParking);
