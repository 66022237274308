import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const getSize = (variant) => {
  switch (variant) {
    case 'h4':
      return 30;
    case 'subtitle2':
      return 22;
    case 'h1':
    default:
      return 80;
  }
};

const useStyles = makeStyles((theme) => ({
  container: {
    width: (props) => getSize(props.variant),
    height: (props) => getSize(props.variant),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (props) => props.color,
    boxShadow: theme.palette.shadow.main,
    color: theme.palette.base.white,
    cursor: 'pointer',
    borderRadius: 5,
    border: 'solid 1px rgba(0, 0, 0, 0.2)',
  },
  parkingLetter: {
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
  }
}));

const ParkingSymbol = ({
  className,
  variant,
  color,
  handleClick,
}) => {
  const classes = useStyles({ variant, color });

  return (
    <div className={classnames(classes.container, className)} onClick={handleClick}>
      <Typography variant={variant} ><div className={classes.parkingLetter} >P</div> </Typography>
    </div>
  );
};

ParkingSymbol.propTypes = {
  className: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  handleClick: PropTypes.func,
};

ParkingSymbol.defaultProps = {
  className: '',
  variant: 'h1',
  color: '#16BBCF',
  handleClick: undefined,
};

export default ParkingSymbol;
