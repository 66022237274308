import React, {useEffect, useState} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {isComponentEnabledForUser, isComponentVisibleForUser} from '../../../../utils';
import CashPayment from './CashPayment';
import {CustomTabs} from "../../../elements";
import {ActionButton} from "../../../common";
import CardPayment from "./CardPayment";
import InvoicePayment from "./InvoicePayment";
import PMCSnackbar from "../../../common/Snackbar";
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ErrorIcon from '@material-ui/icons/Error';
import {Tooltip} from '@material-ui/core';

import {useDispatch} from 'react-redux';
import types from '../../../../redux/actionTypes';

const PAYMENT_TIME_OUT = 60000;
const MESSAGE_TIME_OUT = 3000;

const useStyles = makeStyles((theme) => ({
    container: {
        background: theme.palette.base.white,
        height: '100%',
        width: '100%',
    },
    actions: {
        padding: 0,
    },
    title: {
        height: 96,
        background: theme.palette.secondary.main,

        '& h2': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            fontSize: 26,
            lineHeight: '30px',
            color: theme.palette.base.white,
        },
    },
    button: {
        width: 200,
        height: 75,
        marginLeft: 20,
        marginRight: 20,
        fontSize: 25,
    },
    buttonNavigation: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'flex-end',
    },
    buttonNavigationCancel: {
        display: 'flex',
        background: theme.palette.base.white,
        height: '75px',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        bottom: 0,
        width: '50%',
        justifyContent: 'left',
    },
    tabRow: {
        flexDirection: 'row'
    }
}));


function Payment(props) {
    const {
        userUseCases,
        receipt,
        pay,
        opened,
        handleClose,
        rates,
        currencies,
        snackbarOpen,
        setSnackbarOpen,
        errorPayment,
        posStatutes,

        paymentOpen,
        setPaymentOpen,
        paymentMessage,
        setPaymentMessage,

        handleCloseReceipt,
        ccState,
        setCcState,

        cardDefault
    } = props;


    const classes = useStyles();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const [receiptToPay, setReceiptToPay] = useState({});
    const [receivedAmount, setReceivedAmount] = useState(0);
    const [amountToReturn, setAmountToReturn] = useState(0);
    const [rate, setRate] = useState(1);
    const [selectedCurrency, setSelectedCurrency] = useState();
    
    const [activeTab, setActiveTab] = useState(0);

    const handleChangeTab = (value) => {
        setActiveTab(value);
        if (value === 0) {
            setReceiptToPay({...receipt, 'paymentType': 'CASH'});
        } else if (value === 1) {
            setReceiptToPay({...receipt, 'paymentType': 'CARD'});
        } else if (value === 2) {
            setReceiptToPay({...receipt, 'paymentType': 'INVOICE'});
        }
    };


    useEffect(() => {
        if (receipt) {
            if (!selectedCurrency) {
                setSelectedCurrency(receipt.currency);
                if ( cardDefault ) {
                    setActiveTab(1);
                    setReceiptToPay({...receipt, 'paymentType': 'CARD', 'paymentCurrency': receipt.currency});
                } else {
                    setReceiptToPay({...receipt, 'paymentType': 'CASH', 'paymentCurrency': receipt.currency});
                }
            } else {
                if ( cardDefault ) {
                    setActiveTab(1);
                    setReceiptToPay({...receipt, 'paymentType': 'CARD', 'paymentCurrency': selectedCurrency});
                } else {
                    setReceiptToPay({...receipt, 'paymentType': 'CASH', 'paymentCurrency': selectedCurrency});
                }
            }
        }
    }, [receipt]);

    useEffect(() => {
        console.log('posStatutes', posStatutes);
        if ( posStatutes && posStatutes.length>0 && posStatutes[0].ccPayment !== 'CLEAR' && posStatutes[0].ccPayment !== 'ok') {
            console.log('posStatutes2', posStatutes);
            setPaymentOpen(true);
            if ( posStatutes[0].ccPayment !== ccState && ccState!=='TIMEOUT' )
                setCcState(posStatutes[0].ccPayment);
        }
    }, [posStatutes])

    useEffect(()=>{
        console.log('ccState', ccState);
        let timeout;
        if ( ccState === 'FINISHED' ) {
            setPaymentMessage(t('PAYMENT_FINISHED'));
            timeout = setTimeout(() => {
                handleClose();
            }, 3000);
            dispatch({
                type: types.RECEIPT_PAY_CARD_SUCCESS,
                payload: {receipt: {}},
            });
        } else if ( ccState === 'IN_PROGRESS' ) {
            setPaymentMessage(t('PAYMENT_IN_PROGRESS'));
            timeout = setTimeout(() => {
                setCcState('TIMEOUT');
                setPaymentMessage(t('PAYMENT_TIMEOUT2'));
            }, PAYMENT_TIME_OUT);
        } else if ( ccState === 'CANCELED' ) {
            setPaymentMessage(t('PAYMENT_IN_CANCELED'));
            timeout = setTimeout(() => {
                handleClose();
            }, MESSAGE_TIME_OUT);
        } else if ( ccState === 'TIMEOUT' ) {
            setPaymentMessage(t('PAYMENT_TIMEOUT2'));
        }

        return ( ()=> {
            if ( timeout ) 
                clearTimeout(timeout);
        })

    }, [ccState])

    const onCurrencyChange = (event) => {
        setSelectedCurrency(event.target.value);
        setReceiptToPay({...receipt, 'paymentCurrency': event.target.value});
    }

    const [error, setError] = useState({});


    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        console.log('activeTab', activeTab);
    }, [activeTab]);


    const payLoc = (receipt) => {
        pay(receipt);
    }


    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };


    const isPaymentDisabled = () => {
        if (activeTab === 0) {
            if (amountToReturn < 0) {
                return true;
            }
            if (receivedAmount < (receiptToPay.priceWithVAT * rate)) {
                return true;
            }
        }
        if (activeTab === 1) {
            if ( posStatutes && posStatutes.length > 0 && posStatutes[0].ccState === 'OK' ) {
                return false;
            } else {
                return true;
            }
        }

        return false;
    }

    const tabs = [];
    const tabContents = [];

    const handleChange = (name) => (event) => {
        setReceiptToPay({...receiptToPay, [name]: event.target.value});
    }


    if (isComponentVisibleForUser(UC_TAB_CASH_PAYMENT, userUseCases)) {
        tabs.push(t('CASH'));
        tabContents.push(<CashPayment
            receipt={receiptToPay}
            userUseCases={userUseCases}
            rates={rates}
            currencies={currencies}
            onCurrencyChange={onCurrencyChange}
            selectedCurrency={selectedCurrency}
            receivedAmount={receivedAmount}
            setReceivedAmount={setReceivedAmount}
            amountToReturn={amountToReturn}
            setAmountToReturn={setAmountToReturn}
            rate={rate}
            setRate={setRate}
        />);
    }
    ;

    if (isComponentVisibleForUser(UC_TAB_CARD_PAYMENT, userUseCases)) {
        tabs.push(
            <div className={classes.tabRow}>
                {t('CARD')}
                {posStatutes && posStatutes.length > 0 && posStatutes[0].ccState === 'OK' &&
                    <Tooltip title={t('PAYMENT_TOOLTIP_MESSAGE_OK')}>
                        <CreditCardIcon color='primary' />
                    </Tooltip>
                }
                {!posStatutes || ( posStatutes.length > 0 && posStatutes[0].ccState !== 'OK' ) &&
                    <Tooltip title={t('PAYMENT_TOOLTIP_MESSAGE_ERROR')}>
                        <ErrorIcon color='error' toolt/>
                    </Tooltip>
                }
            </div>
        );
        tabContents.push(<CardPayment
            receipt={receiptToPay}
            userUseCases={userUseCases}
            rates={rates}
            currencies={currencies}
            onCurrencyChange={onCurrencyChange}
            selectedCurrency={selectedCurrency}

            paymentOpen={paymentOpen}
            paymentMessage={paymentMessage}
            ccState={ccState}
            handleCloseReceipt={handleCloseReceipt}
        />);
    }
    ;
    if (isComponentVisibleForUser(UC_TAB_INVOICE_PAYMENT, userUseCases)) {
        tabs.push(t('INVOICE'));
        tabContents.push(<InvoicePayment
            receipt={receiptToPay}
            userUseCases={userUseCases}
            rates={rates}
            currencies={currencies}
            onCurrencyChange={onCurrencyChange}
            selectedCurrency={selectedCurrency}
            handleChange={handleChange}
            error={error}
        />);
    }
    ;


    return (
        <Dialog
            open={opened}
            // onClose={handleClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle
                className={
                    classnames(classes.title)
                }
            >
              <span>
                    {t('PAYMENT')}
              </span>
            </DialogTitle>


            <PMCSnackbar open={snackbarOpen} onClose={handleCloseSnackbar} severity="info" message={errorPayment} />

            <DialogContent className={classes.container}>
                <CustomTabs
                    tabs={tabs}
                    variant="fullWidth"
                    tabContents={tabContents}
                    handleChangeTab={handleChangeTab}
                    defaultTab={cardDefault?1:0}
                />
            </DialogContent>
            <DialogActions className={classes.actions}>
                <div className={classes.buttonNavigationCancel}>
                    <ActionButton
                        action="close"
                        handleClick={handleClose}
                        className={classes.button}
                        disabled={paymentOpen}
                    />
                </div>
                <div className={classes.buttonNavigation}>
                    {isComponentVisibleForUser(UC_TAB_PAY_AND_CLOSE, userUseCases) &&

                    <ActionButton
                        action={'pay'}
                        handleClick={() => {
                            payLoc(receiptToPay)
                        }}
                        disabled={isPaymentDisabled() || !isComponentEnabledForUser(UC_TAB_PAY_AND_CLOSE, userUseCases) || paymentOpen}
                        className={classes.button}
                    />
                    }
                </div>
            </DialogActions>
        </Dialog>
    )
        ;
}

Payment.propTypes = {};

const UC_TAB_CASH_PAYMENT = 'UC0454';
const UC_TAB_CARD_PAYMENT = 'UC0455';
const UC_TAB_INVOICE_PAYMENT = 'UC0456';
const UC_TAB_PAY_AND_CLOSE = 'UC0457';


const mapStateToProps = (store) => ({
    posStatutes: store.parkingsData.posStatutes,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Payment);


