import React, {useState, useEffect, useRef, useCallback} from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";

import {
    getZoneItems,
    getFormattedStr,
    getCardGroups,
    getParkingAccess,
} from 'utils';
import CardParkingSettings from './CardParkingSettings';
import CardJournals from './CardJournals';
import IconButton from "@material-ui/core/IconButton";
import {ArrowBackOutlined, Title} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";

import CardOwnersTable from 'containers/Parking/SubjectParking/CardOwners/CardOwnersTable';
import { fetchSubjectOwners, fetchSubjectSearchOwners, activateOwner, updateOwner } from 'redux/actions/owners';

import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import {CustomCheckbox,CustomSelect} from 'components/elements';
import {useTranslation} from 'react-i18next';
import {getCodeList} from 'utils';
import {ActionButton} from 'components/common';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
    },

    table: {
      width: '100%',
      flexDirection: 'column',
      display: 'flex',
      padding: theme.spacing(1, 1, 1, 1),
      justifyContent: 'space-between',
    },

    title: {
      height: 60,
      width: '100%',
      background: 'green',
      paddingRight: theme.spacing(2),
      '& h2': {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: 20,
          marginBlockStart: 0,
          marginBlockEnd: 0,
          lineHeight: '20px',
          color: theme.palette.base.white,
          '& .MuiButtonBase-root': {
              color: theme.palette.base.white,
          },
          '& input + fieldset': {
              borderColor: theme.palette.base.white
          },
      },
  },

  containerOwner: {
      height: '100%',
      width: '100%',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'center',
  },
  content: {
      display: 'flex',
      width: '100%',
  },
  contentColumn: {
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
  },
  icon: {
      height: '20px',
      width: '20px',
  },
  formGroup: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'left',

    '&:last-child': {
        marginBottom: 0,
    },
  },
  wrapper: {
      flex: 1,
      display: 'flex',
      alignItems: 'left',
  },
  buttonWrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row-reverse',
  },
  textField: {
      flex: 1,
      paddingLeft:'10px'
  },
  select: {
    flex: 1,

    '& .MuiSelect-select': {
        lineHeight: '20px',
        borderBottom: '1px solid #ADB0B8',
    },
  },
  mr: {
    marginRight: theme.spacing(2.5),
    minWidth: '160px',
  },
}));

const filterParams = {
    currentKeyword: '',
    validity: [''],
    size: 5,
    page: 0,
  };

function CardParkingDetail(props) {
    const classes = useStyles();

    const {
        isLT,
        card,
        cardDetail,
        selectedCard,
        detailParking,
        cardParkings,
        parkingAccesses,
        parkingCardGroups,
        handleClose,
        handleChangeCard,
        handleActivateCard,
        handleBlockCard,
        updateCard,
        userUseCases,
        productMode,
        handleChangeTab,
        detailParkings,
        error,
        setError,
        handleOwnerSelectTab,
        handleOwnerSelectObject,

        subjectOwners,
        subjectSearchOwners,
        ownersPaging,
        fetchSubjectOwners,
        fetchSubjectSearchOwners,
        updateOwner,
        user,
        activateOwner,
        mode, setMode,

        auth,
        allCodeList,
    } = props;

    const selectedParking = cardParkings.find((parking) => parking.parkingId === detailParking.parkingId);

    const zones = getZoneItems(selectedParking.zoneStatus);

    const cardGroups = getCardGroups(parkingCardGroups);
    const accesses = getParkingAccess(parkingAccesses);

    const selectedCardZone = detailParking.zones[0];

    const {t} = useTranslation();

    const handleChangeSetting = (setting) => {
        const selectedZone = setting.zone && zones.length > 0
            ? zones.find((z) => z.value === setting.zone)
            : selectedCardZone;
        const selectedZoneReservation = setting.zoneReservationId && zones.length > 0
            ? zones.find((z) => z.value === setting.zoneReservationId)
            : null;
        const selectedAccess = accesses.find((a) => a.value === setting.access);

        const newZone = {
            ...selectedCardZone,
            cardGroups: setting.cardGroups,
            lpn: setting.carNumber,
            note: setting.comment,
            parkingAccessId: selectedAccess ? selectedAccess.id : null,
            validFrom: getFormattedStr(setting.validFrom),
            validFromTS: new Date(setting.validFrom).getTime(),
            validTo: getFormattedStr(setting.validTo),
            validToTS: new Date(setting.validTo).getTime(),
            credit: setting.credit,
            zoneId: selectedZone.zoneId,
            startZoneId: selectedZone ? selectedZone.zoneId : selectedCardZone.startZoneId,
            zoneName: selectedZone ? selectedZone.label : selectedCardZone.zoneName,
            zoneReservationId: selectedZoneReservation ? selectedZoneReservation.zoneId : null,
            blocked: false,
            valid: true,
        };

        const newParkings = cardDetail.parkings;
        const newParkingIndex = newParkings.findIndex((parking) => parking.parkingId === detailParking.parkingId);
        const newZoneIndex = newParkings[newParkingIndex].zones.findIndex((zone) => zone.cardZoneId === newZone.cardZoneId);
        newParkings[newParkingIndex].zones[newZoneIndex] = newZone;

        handleChangeCard({
            ...cardDetail,
            parkings: newParkings,
        });
    };


    const handleUpdateCard = (setting) => {
        const validToTS = new Date(setting.validTo).getTime();
        const validFromTS = new Date(setting.validFrom).getTime();
        if (validFromTS > validToTS) {
            return;
        }
        for (let j = 0; j < cardDetail.parkings.length; j++) {
            const zone = cardDetail.parkings[j].zones[0];
            if (!zone.parkingAccessId) {

                setError({
                    [`access${cardDetail.parkings[j].parkingId}`]: true,
                });
                const p = detailParkings.findIndex((parking) => parking.parkingId === cardDetail.parkings[j].parkingId);
                if (p > 0) {
                    handleChangeTab(p);
                }
                return;
            }
        }
        handleChangeSetting(setting);
        updateCard(cardDetail);
    };

    const isUnMounted = useRef(false);
    useEffect(() => {
      isUnMounted.current = false;
      return () => {
        isUnMounted.current = true;
      };
    }, []);

    const [isLoadingOwners, setIsLoadingOwners] = useState(true);
    const [isFetchedOwners, setIsFetchedOwners] = useState(false);
    const [isFetchedSearchOwners, setIsFetchedSearchOwners] = useState(true);
    
    const fetchOwners = useCallback((validity, search, size, page) => {
      const validityStr = validity.map((valid) => {
        if (!valid) {
          return '';
        }
  
        return valid === 'valid';
      });
  
      if (
        user
        && user.subjectId
      ) {
        fetchSubjectOwners(user.subjectId, validityStr, search, size, page)
          .then(() => {
            if (!isUnMounted.current) {
              setIsLoadingOwners(false);
              setIsFetchedOwners(true);
              if (
                JSON.stringify(filterParams.validity) !== JSON.stringify(validity)
                || filterParams.size !== size
                || filterParams.page !== page
              ) {
                setIsFetchedOwners(false);
              }
            }
          })
          .catch(() => {
            if (!isUnMounted.current) {
              setIsLoadingOwners(true);
              setIsFetchedOwners(false);
            }
          });
      }
    }, [fetchSubjectOwners, user]);
  
    const fetchSearchOwners = useCallback((keyword) => {
      if (keyword) {
        fetchSubjectSearchOwners(keyword)
          .then(() => {
            if (!isUnMounted.current) {
              setIsFetchedSearchOwners(true);
              if (filterParams.currentKeyword !== keyword) {
                setIsFetchedSearchOwners(false);
              }
            }
          })
          .catch(() => {
            if (!isUnMounted.current) {
              setIsFetchedSearchOwners(false);
            }
          });
      }
    }, [fetchSubjectSearchOwners]);
  
    const [validity, setValidity] = useState(['']);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [size, setSize] = useState(5);
    const [page, setPage] = useState(0);
    const [ownerForm, setOwnerForm] = useState(false);
    const resetPagination = () => {
      filterParams.page = 0;
      setPage(0);
    };
    const handleValidity = (value) => {
      let validity = value;
      if (validity.length === 3) {
        validity = [''];
      }
      filterParams.validity = validity;
      resetPagination();
      setIsFetchedOwners(false);
      setValidity(validity);
    };
    const handleChangeRowsPerPage = (value) => {
      filterParams.size = value;
      resetPagination();
      setIsFetchedOwners(false);
      setSize(value);
    };
    const handleChangePage = (value) => {
      filterParams.page = value;
      setIsFetchedOwners(false);
      setPage(value);
    };
    const handleSearch = (value) => {
      filterParams.currentKeyword = value;
      resetPagination();
      setIsFetchedSearchOwners(false);
      setIsFetchedOwners(false);
      setSearchKeyword(value);
    };

    useEffect(() => {
        if (!isFetchedOwners) {
          fetchOwners(validity, searchKeyword, size, page);
        }
      }, [validity, isFetchedOwners, size, page, fetchOwners, searchKeyword]);

    useEffect(() => {
        if (!isFetchedSearchOwners) {
          fetchSearchOwners(searchKeyword);
        }
    }, [isFetchedSearchOwners, fetchSearchOwners, searchKeyword]);

    const ownersData = subjectOwners.map((owner) => ({
        ownerId: owner.ownerId,
        firstName: owner.firstname,
        lastName: owner.surname,
        subjectName: owner.subjectName,
        valid: owner.valid,
        phone1: owner.phone1,
        phone2: owner.phone2,
        note: owner.note
    }));

    const handleActivate = (ownerId, activate) => () => {
        activateOwner(ownerId, activate);
    };

    const ownerDetailOpen = (event) => () => {
        handleOwnerDetailOpen(event);
        setOwnerForm(true);
    };

    const handleOwnerDetailOpen = (event) => {
      setState({
        ...state,
        "ownerId": "",
        "firstname": "",
        "surname": "",
        "phone1": "",
        "phone2": "",
        "subjectName": "",
        "note": "",
        "valid": "",
        "selectedRow": null,
      });

      if ( event && event.firstName ) {
        setState({
          ...state,
          "ownerId": event.ownerId==null?"":event.ownerId,
          "firstname": event.firstName==null?"":event.firstName,
          "surname": event.lastName==null?"":event.lastName,
          "phone1": event.phone1==null?"":event.phone1,
          "phone2": event.phone2==null?"":event.phone2,
          "subjectId": user.subjectId,
          "note": event.note==null?"":event.note,
          "valid": event.valid==null?"":event.valid,
          "selectedRow":event.selectedRow,
        });
      }

      if ( event && !event.firstName && event.ownerId ) {
        handleOwnerSelectTab(event.ownerId);
        setMode('COMMON');        
      }
    }

    const handleCancel = () => () => {
      handleOwnerDetailOpen();
      setOwnerForm(false);
      setState({
         ...state,
         selectedRow: '',
        });
    };

    const handleSave = () => () => {
      updateOwner({
        firstname: state.firstname,
        surname: state.surname,
        phone1: state.phone1,
        phone2: state.phone2,
        note: state.note,
        valid: state.valid,
        group: state.group,
        ownerId: state.ownerId,
        subjectId: state.subjectId!==null?state.subjectId:user.subjectId,
      })
        .then(()=>{
          handleOwnerSelectObject({
            ownerId: state.ownerId,
            firstname: state.firstname,
            surname: state.surname,
            distinct: ''
          });
          setMode('COMMON');
        });

      setIsFetchedOwners(false);
    }

    const toState = useCallback((od) => ({
      id: od.ownerId,
      firstname: od.firstname || '',
      surname: od.surname || '',
      companyName: od.companyName || '',
      parkingSlot: od.parkingSlot || '',
      phone1: od.phone1 || '',
      phone2: od.phone2 || '',
      note: od.note || '',
      valid: od.valid,
      subjectId: od.subjectId
          || (user && user.subjectId),
      group: od.group || '',
    }), [user]);
    const [state, setState] = useState(toState(ownersData));
    const isDisabled = auth ? auth.isRO : false;
    const groupItems = getCodeList(allCodeList, 'OWNER_GROUP');
    const groups = groupItems.length > 0 ? groupItems.map((item) => ({
        value: `group_${item.codeListItemId}`,
        label: item.code,
        key: `group_${item.codeListItemId}`,
    })) : [];
    const handleChange = (field) => (event) => {
        switch (field) {
            case 'firstname':
            case 'surname':
            case 'companyName':
            case 'note':
                return setState({
                    ...state,
                    [field]: event.target.value,
                });
            case 'phone1':
            case 'phone2':
                return event.target.value.toString().length < 31
                    && setState({
                        ...state,
                        [field]: event.target.value,
                    });
            default:
                return setState({
                    ...state,
                    [field]: event,
                });
        }
    };


    const ownerDetailShow = (
        <>
          <div className={classes.title}>
            <h2>{state.ownerId ? t('CARD_OWNER_UPDATE') : t('CARD_OWNER_CREATE')}</h2>
          </div>

          <Paper className={classes.table}>
          
              <Box className={classes.formGroup}>
                  <Box className={classes.wrapper}>
                      <TextField
                          label={t('CARD_OWNER_NAME')}
                          autoFocus
                          required
                          className={classes.textField}
                          hiddenLabel
                          disabled={isDisabled}
                          value={state.firstname}
                          onChange={handleChange('firstname')}
                      />
                  </Box>

                  <Box className={classes.wrapper}>
                      <TextField
                          required
                          label={t('CARD_OWNER_SURNAME')}
                          className={classes.textField}
                          hiddenLabel
                          disabled={isDisabled}
                          value={state.surname}
                          onChange={handleChange('surname')}
                      />
                  </Box>
              </Box>

              <Box className={classes.formGroup}>
                  <Box className={classes.wrapper}>
                      <TextField
                          required
                          label={t('CARD_OWNER_PHONE_1')}
                          className={classes.textField}
                          hiddenLabel
                          disabled={isDisabled}
                          value={state.phone1}
                          type="number"
                          onChange={handleChange('phone1')}
                      />
                  </Box>

                  <Box className={classes.wrapper}>
                      <TextField
                          required
                          label={t('CARD_OWNER_PHONE_2')}
                          className={classes.textField}
                          hiddenLabel
                          disabled={isDisabled}
                          value={state.phone2}
                          type="number"
                          onChange={handleChange('phone2')}
                      />
                  </Box>
              </Box>

              <Box className={classes.formGroup}>
                  <Box className={classes.wrapper}>
                      <CustomSelect
                          label={t('CARD_OWNER_GROUPS')}
                          value={state.group || ''}
                          items={groups}
                          className={classes.select}
                          disabled={isDisabled}
                          placeholder={t('CARD_OWNER_GROUPS')}
                          handleChange={handleChange('group')}
                      />
                  </Box>

                  <Box className={classes.wrapper}>
                      <CustomCheckbox
                          label={t('CARD_OWNER_VALIDITY')}
                          checked={state.valid}
                          disableCheck={isDisabled}
                          handleChange={handleChange('valid')}
                      />
                  </Box>
              </Box>

              <Box className={classes.formGroup}>
                  <TextField
                      className={classes.textField}
                      label={t('CARD_OWNER_NOTE')}
                      hiddenLabel
                      multiline
                      rowsMax="2"
                      inputProps={{
                          maxLength: 150,
                      }}
                      disabled={isDisabled}
                      value={state.note}
                      helperText={!isDisabled && (
                          <span>
                  {`${150 - (state.note ? state.note.length : 0)} characters left`}
                </span>
                      )}
                      onChange={handleChange('note')}
                  />
              </Box>

              <div className={classes.buttonWrapper}>
                <ActionButton
                  handleClick={handleSave()}
                  className={classes.mr}
                  action="save"
                />
                <ActionButton
                  action="cancle"
                  className={classes.mr}
                  handleClick={handleCancel()}
                />
              </div>
          </Paper>
        </>
    )

    return (
        <div className={mode==='COMMON'?classes.container:classes.containerOwner}>

            {mode==='COMMON' &&
            <div className={productMode ? classes.contentColumn : classes.content}>
                <CardParkingSettings
                    productMode={productMode}
                    error={error}
                    isLT={isLT}
                    card={card}
                    cardGroups={cardGroups}
                    accesses={accesses}
                    selectedParking={selectedParking}
                    zones={zones}
                    cardZone={selectedCardZone}
                    detailParking={detailParking}
                    cardParkings={cardParkings}
                    handleSubmitChange={handleChangeSetting}
                    handleClose={handleClose}
                    handleUpdateCard={handleUpdateCard}
                    userUseCases={userUseCases}
                />

                <CardJournals
                    isLT={isLT}
                    selectedCard={selectedCard}
                    detailParking={detailParking}
                    handleActivateCard={handleActivateCard}
                    handleBlockCard={handleBlockCard}
                    userUseCases={userUseCases}
                    productMode={productMode}
                />
            </div> }
            {mode!=='COMMON' &&
            <IconButton onClick={() => {
              {
                handleCancel()
                setMode('COMMON')

              }
            }} className={classes.iconButton}>
                <ArrowBackOutlined className={classes.icon}/>
            </IconButton>
            }
            {mode==='OWNER' &&
            <Grid container spacing={2}>
              <Grid item md={ownerForm ? 8:12}>
                <CardOwnersTable
                  userUseCases={userUseCases}
                  ownersData={ownersData}
                  subjectSearchOwners={subjectSearchOwners}
                  ownersPaging={ownersPaging}
                  isLoadingOwners={isLoadingOwners}
                  validity={validity}
                  ownerDetailOpen={ownerDetailOpen}
                  handleOwnerDetailOpen={handleOwnerDetailOpen}
                  handleActivate={handleActivate}
                  handleValidity={handleValidity}
                  handleSearch={handleSearch}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  handleChangePage={handleChangePage}
                  showForm={setOwnerForm}
                  options={{
                    rowStyle: (rowData) => ({
                      backgroundColor:
                      state.selectedRow === rowData.tableData.id ? "#F0F0F0" : "#FFF",
                    }),}
                  }
                />
              </Grid>
              { ownerForm &&
              <Grid item md='4'>
                {ownerDetailShow}
              </Grid>}
            </Grid>
        }
        </div>
    );
}

CardParkingDetail.propTypes = {
    isLT: PropTypes.bool.isRequired,
    card: PropTypes.object.isRequired,
    cardDetail: PropTypes.object.isRequired,
    selectedCard: PropTypes.object,
    detailParking: PropTypes.object.isRequired,
    cardParkings: PropTypes.array.isRequired,
    parkingAccesses: PropTypes.array.isRequired,
    parkingCardGroups: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleChangeCard: PropTypes.func.isRequired,
    handleActivateCard: PropTypes.func.isRequired,
    handleOwnerSelectTab: PropTypes.func.isRequired,
    handleBlockCard: PropTypes.func.isRequired,
    updateCard: PropTypes.func.isRequired,
    fetchSubjectOwners: PropTypes.func.isRequired,
    fetchSubjectSearchOwners: PropTypes.func.isRequired,
  };

CardParkingDetail.defaultProps = {
    selectedCard: {},
};

const mapStateToProps = (store) => ({
    subjectOwners: store.ownersData.subjectOwners,
    ownersPaging: store.ownersData.ownersPaging,
    allCodeList: store.codesData.allCodeList,
    subjectSearchOwners: store.ownersData.subjectSearchOwners,
  });

  const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchSubjectOwners,
    fetchSubjectSearchOwners,
    activateOwner,
    updateOwner,
  }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps,
  )(CardParkingDetail);