import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';

import { CustomDateTimePicker } from 'components/elements';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    height: 55,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #E0E0E0',
    paddingBottom: theme.spacing(4.5),
  },
  onlineSwitch: {
    display: 'flex',
    alignItems: 'center',
  },
  onlineSwitchLabel: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
  },
  toolbarInput: {
    width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
}));

function StatisticsToolbar(props) {
  const classes = useStyles();

  const {
    startTimeStr,
    endTimeStr,
    handleStartDate,
    handleEndDate,
  } = props;

  const { t } = useTranslation();

  return (
    <div className={classes.toolbar}>
      <div className={classes.toolbarInput}>
        <CustomDateTimePicker
          label={t('FROM')}
          value={startTimeStr}
          icon={<ArrowDropDown />}
          handleChange={handleStartDate}
        />
        <CustomDateTimePicker
          label={t('TO')}
          value={endTimeStr}
          icon={<ArrowDropDown />}
          handleChange={handleEndDate}
        />
      </div>
    </div>
  );
}

StatisticsToolbar.propTypes = {
  startTimeStr: PropTypes.object,
  endTimeStr: PropTypes.object,
  handleStartDate: PropTypes.func.isRequired,
  handleEndDate: PropTypes.func.isRequired,
};

StatisticsToolbar.defaultProps = {
  startTimeStr: null,
  endTimeStr: null,
};

export default StatisticsToolbar;
