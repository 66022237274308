import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {makeStyles} from '@material-ui/core/styles';
import Dialog from "@material-ui/core/Dialog";
import IconButton from "@material-ui/core/IconButton/IconButton";
import DialogContent from "@material-ui/core/DialogContent";
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from "@material-ui/icons/Search";
import {InputBase} from "@material-ui/core";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {search} from "../../../redux/actions/fulltext";
import FulltextTable from "./OnlineEventsTable";
import debounce from "lodash.debounce";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import CustomDateTimePicker from "../../elements/CustomDateTimePicker";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {fetchParkingHosts} from "../../../redux/actions/hosts";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormHelperText from "@material-ui/core/FormHelperText";

const useStyles = makeStyles((theme) => ({
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.base.white,
    },
    searchContent: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        marginBottom: '20px',
        borderBottom: `3px solid ${theme.palette.base[200]}`,
    },
    dialogPaper: {
        height: props => `${props.height}px`,
    },
    search: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    searchIcon: {
        padding: theme.spacing(1),
    },
}));

const Fulltext = React.memo(function Fulltext0(props) {
    const classes = useStyles();
    const {t} = useTranslation();

    const {
        opened,
        parkingHosts,
        handleClose,
        onSubmit,
        search,
        data,
        userUseCases,
        parkings,
        selectParking,
        handleCardNameClick,
        isFetching,
        fetchParkingHosts,
        handleHostNameClick,
    } = props;

    /*    1 den
        3 dny
        1 týden
        1 měsíc
        6 měsíců
        1 rok*/

    const day = 1000 * 60 * 60 * 24;
    const currentDate = new Date();
    const currentDateStart = new Date().setHours(0, 0, 0, 0);
    const last3days = new Date(currentDate - day * 3);
    const last7days = new Date(currentDate - day * 7);
    const last31days = new Date(currentDate - day * 31);
    const last180days = new Date(currentDate - day * 180);
    const last365days = new Date(currentDate - day * 365);

    const intervals = []
    intervals.push({name: '-'});
    intervals.push({name: t('TODAY'), dateFrom: currentDateStart, dateTo: currentDate});
    intervals.push({name: t('3DAYS'), dateFrom: last3days, dateTo: currentDate});
    intervals.push({name: t('1WEEK'), dateFrom: last7days, dateTo: currentDate});
    intervals.push({name: t('1MONTH'), dateFrom: last31days, dateTo: currentDate});
    intervals.push({name: t('6MONTHS'), dateFrom: last180days, dateTo: currentDate});
    intervals.push({name: t('1YEAR'), dateFrom: last365days, dateTo: currentDate});


    const [error, setError] = useState(false);
    const [state, setState] = useState({});
    const [searchString, setSearchString] = useState('');
    const [advancedFilterOpen, setAdvancedFilterOpen] = useState(false);
    const [lpn, setLPN] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [owner, setOwner] = useState('');
    const [dateFrom, setDateFrom] = useState(null);
    const [interval, setInterval] = useState(null);
    const [dateTo, setDateTo] = useState(null);
    const [selectedParkings, setSelectedParkings] = useState([]);
    const [selectedHosts, setSelectedHosts] = useState([]);



    useEffect(() => {
        if (opened) {
            search({hosts: selectedHosts, parkings: selectedParkings, dateFrom, dateTo, search: searchString, lpn, cardNumber, owner});
        }
    }, [opened]);

    useEffect(() => {
        if (selectedParkings.length === 1) {
            fetchParkingHosts(selectedParkings[0].parkingId);
        } else {
            setSelectedHosts([]);
        }
    }, [selectedParkings]);



    const searchInner = (hosts, parkings, dateFrom, dateTo, searchValue, lpn, cardNumber, owner) => {
        search({hosts, parkings, dateFrom, dateTo, search: searchValue, lpn, cardNumber, owner});
    };

    const debounceFn = useCallback(debounce(searchInner, 1000), []);

    function handleIntervalChange(event) {
        const selectedInterval = intervals.find(a => a.name === event.target.value);
        setInterval(event.target.value);
        if (selectedInterval && selectedInterval.dateFrom) {
            setDateFrom(selectedInterval.dateFrom);
            setDateTo(selectedInterval.dateTo);
            debounceFn(selectedHosts, selectedParkings, selectedInterval.dateFrom, selectedInterval.dateTo, searchString, lpn, cardNumber, owner);
        } else {
            setDateFrom(null);
            setDateTo(null);
            debounceFn(selectedHosts, selectedParkings, null, null, searchString, lpn, cardNumber, owner);
        }
    }

    function handleChangeSearchString(event) {
        setSearchString(event.target.value);
        debounceFn(selectedHosts, selectedParkings, dateFrom, dateTo, event.target.value, lpn, cardNumber, owner);
    };

    function handleChangeLPN(event) {
        setLPN(event.target.value);
        debounceFn(selectedHosts, selectedParkings, dateFrom, dateTo, searchString, event.target.value, cardNumber, owner);
    }

    function handleChangeCardNumber(event) {
        setCardNumber(event.target.value);
        debounceFn(selectedHosts, selectedParkings, dateFrom, dateTo, searchString, lpn, event.target.value, owner);
    };


    function handleChangeOwner(event) {
        setOwner(event.target.value);
        debounceFn(selectedHosts, selectedParkings, dateFrom, dateTo, searchString, lpn, cardNumber, event.target.value);
    };

    function handleChangeDateFrom(event) {
        setDateFrom(event);
        debounceFn(selectedHosts, selectedParkings, event, dateTo, searchString, lpn, cardNumber, owner);
    };

    function handleChangeDateTo(event) {
        setDateTo(event);
        debounceFn(selectedHosts, selectedParkings, dateFrom, event, searchString, lpn, cardNumber, owner);
    };

    function handleParkingsChange(parkings) {
        setSelectedParkings(parkings || []);
        debounceFn(selectedHosts, parkings, dateFrom, dateTo, searchString, lpn, cardNumber, owner);
    };

    function handleHostsChange(hosts) {
        setSelectedHosts(hosts || []);
        debounceFn(hosts, parkings, dateFrom, dateTo, searchString, lpn, cardNumber, owner);
    };

    return (

        <Dialog
            disableEnforceFocus
            maxWidth='lg'
            open={opened}
            onClose={handleClose}
            classes={{paper: classes.dialogPaper}}
        >
            <DialogContent className={classes.content}>
                <div className={classes.searchContent}>
                    <div className={classes.searchIcon}>
                        <SearchIcon fontSize="large"/>
                    </div>
                    <InputBase
                        type="search"
                        autoFocus
                        placeholder={t('SEARCH_DOT')}
                        className={classes.search}
                        inputProps={{'aria-label': 'search'}}
                        value={searchString}
                        onChange={handleChangeSearchString}
                        readOnly={isFetching}
                    />
                    <IconButton className={classes.searchIcon} aria-label="menu" onClick={() => {
                        setAdvancedFilterOpen(!advancedFilterOpen);
                    }}>
                        <MenuIcon fontSize="large"/>
                    </IconButton>
                </div>
                {advancedFilterOpen &&
                    <Grid container spacing={1} alignItems="stretch" style={{
                        paddingBottom: '20px',
                    }}>
                        <Grid item xs sm={4}>
                            <TextField
                                fullWidth
                                type="search"
                                id="licencePlate"
                                value={lpn}
                                onChange={handleChangeLPN}
                                label={t('LICENSE_PLATE')}
                                readOnly={isFetching}
                            />
                        </Grid>
                        <Grid item xs sm={4}>
                            <TextField
                                fullWidth
                                type="search"
                                id="cardNumber"
                                value={cardNumber}
                                onChange={handleChangeCardNumber}
                                label={t('CARD_NUMBER')}
                                readOnly={isFetching}
                            />
                        </Grid>
                        <Grid item xs sm={4}>
                            <TextField
                                fullWidth
                                type="search"
                                id="owner"
                                value={owner}
                                onChange={handleChangeOwner}
                                label={t('CARD_OWNER')}
                                readOnly={isFetching}
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <FormControl fullWidth>
                                <InputLabel id="product-type-label">
                                    {t('INTERVAL')}
                                </InputLabel>
                                <Select
                                    autoWidth
                                    value={interval ? interval : ''}
                                    id="interval"
                                    onChange={handleIntervalChange}
                                    disabled={isFetching}
                                >
                                    {intervals.map(a => {
                                        return (
                                            <MenuItem value={a.name}>{a.name}</MenuItem>
                                        )
                                    })
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item sm={4}>
                            <CustomDateTimePicker
                                pure={true}
                                handleChange={handleChangeDateFrom}
                                fullWidth={true}
                                value={dateFrom}
                                label={t('FROM')}
                                disabled={isFetching}
                            >
                            </CustomDateTimePicker>
                        </Grid>
                        <Grid item sm={4}>
                            <CustomDateTimePicker
                                pure={true}
                                handleChange={handleChangeDateTo}
                                fullWidth={true}
                                value={dateTo}
                                label={t('TO')}
                                disabled={isFetching}
                            >
                            </CustomDateTimePicker>
                        </Grid>

                        <Grid item sm={6}>

                            <Autocomplete
                                multiple
                                value={selectedParkings.map(a => {
                                        const p = parkings.find(b => b.parkingId === a.parkingId);
                                        if (p) {
                                            return p;
                                        } else {
                                            return {parkingId: a, parkingName: 'NOT FOUND'}
                                        }
                                    }
                                )}
                                onChange={(event, newValue) => {
                                    handleParkingsChange(newValue);
                                }}
                                getOptionSelected={(option, value) => option.parkingId === value.parkingId}
                                filterSelectedOptions
                                id="parkingId"
                                options={parkings}
                                getOptionLabel={(option) => {
                                    return option.parkingName;
                                }}
                                style={{width: '100%'}}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('PARKING')} fullWidth/>
                                )}
                                size="small"
                                disabled={isFetching}
                            />
                        </Grid>

                        <Grid item sm={6}>

                            <Autocomplete
                                disabled={selectedParkings.length !== 1 || parkingHosts.length === 0 || isFetching}
                                multiple
                                value={selectedHosts.map(a => {
                                        const p = parkingHosts.find(b => b.parkingHostId === a.parkingHostId);
                                        if (p) {
                                            return p;
                                        } else {
                                            return {parkingHostId: a, hostName: 'NOT FOUND'}
                                        }
                                    }
                                )}
                                onChange={(event, newValue) => {
                                    handleHostsChange(newValue);
                                }}
                                getOptionSelected={(option, value) => option.parkingHostId === value.parkingHostId}
                                filterSelectedOptions
                                id="hostId"
                                options={parkingHosts}
                                getOptionLabel={(option) => {
                                    return option.hostName;
                                }}
                                style={{width: '100%'}}
                                renderInput={(params) => (
                                    <TextField {...params} label={t('PARKING_HOST')} fullWidth/>
                                )}
                                size="small"
                            />
                        </Grid>


                    </Grid>}
                {data && <FulltextTable
                    handleCardNameClick={handleCardNameClick}
                    handleHostNameClick={handleHostNameClick}
                    parkings={parkings}
                    userUseCases={userUseCases}
                    events={data}
                    selectParking={selectParking}
                    isLoading={isFetching}
                    advancedFilterOpen={advancedFilterOpen}
                    handleClose={handleClose}
                    searchQuery={searchString}
                    owner={owner}
                    cardNumber={cardNumber}
                    lpn={lpn}
                />}
            </DialogContent>
        </Dialog>
    );
}, (o1, o2)=>{
    return o1.opened === o2.opened && o1.isFetching === o2.isFetching && o1.parkings.count === o2.parkings.count && o1.data === o2.data
});

Fulltext.propTypes = {
    opened: PropTypes.bool.isRequired,
    parkings: PropTypes.array.isRequired,
    parkingHosts: PropTypes.array.isRequired,
    handleClose: PropTypes.func.isRequired,
    data: PropTypes.array,
    isFetching: PropTypes.bool,
};


Fulltext.defaultProps = {
    data: [],
    parkings: [],
    parkingHosts: [],
    isFetching: false,
};

const mapStateToProps = (store) => ({
    data: store.fulltextData.events,
    isFetching: store.fulltextData.isFetching,
    parkings: store.parkingsData.parkings || [],
    parkingHosts: store.hostsData.parkingHosts || [],
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
    search, fetchParkingHosts,
}, dispatch);


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Fulltext);

