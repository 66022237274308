import React, {useState} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton/IconButton';
import Add from '@material-ui/icons/Add';

import { CustomMultiSelect, CustomSearchInput, CustomTable } from 'components/elements';
import { ActionButton, EventType } from 'components/common';
import {isComponentVisibleForUser, isComponentEnabledForUser} from 'utils';


const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: '100%',
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  mr: {
    marginRight: theme.spacing(2.5),
  },
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  addButtonWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '10%',
  },
  searchField: {
    alignSelf: 'center',
    flexBasis: '70%',
    marginRight: theme.spacing(2.5),
  },
  toolbarInput: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
  },
  addButton: {
    backgroundColor: theme.palette.base[100],
    marginLeft: theme.spacing(2),
    padding: theme.spacing(1.125),
  },
  addIcon: {
    width: 30,
    height: 30,
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 'auto',

    '& > div': {
      width: 180,
      marginLeft: theme.spacing(4),
    },

    '& > div:first-child': {
      marginLeft: theme.spacing(2),
    },
  },
  title: {
    height: 60,
    width: '100%',
    background: 'green',
    paddingRight: theme.spacing(2),
    '& h2': {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 20,
        marginBlockStart: 0,
        marginBlockEnd: 0,
        lineHeight: '20px',
        color: theme.palette.base.white,
        '& .MuiButtonBase-root': {
            color: theme.palette.base.white,
        },
        '& input + fieldset': {
            borderColor: theme.palette.base.white
        },
    },
  },
}));

const cellStyle = {
  paddingLeft: 4,
  paddingRight: 4,
  lineHeight: '19px',
  color: '#60636B',
  textAlign: 'center',
};

function CardOwnersTable(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    ownersData,
    subjectSearchOwners,
    ownersPaging,
    isLoadingOwners,
    validity,
    handleOwnerDetailOpen,
    ownerDetailOpen,
    handleActivate,
    handleValidity,
    handleSearch,
    handleChangeRowsPerPage,
    handleChangePage,
    userUseCases,
    showForm,
    options,
  } = props;

  const validityStatus = [
    {
      value: 'valid',
      label: t('VALID'),
      key: 'valid',
    },
    {
      value: 'invalid',
      label: t('NON_VALID'),
      key: 'invalid',
    },
  ];

  const showOwner = (data) => () => {
    const row = data.rowData;
    ownerDetailOpen({
      ...row,
      editable: false,
    })
    setSelectedRow(row.tableData.id)
  }
  const columns = [
    {
      title: t('OWNER_NUMBER'),
      field: 'ownerId',
      cellStyle,
      render: (rowData) => {
        return isComponentVisibleForUser(UC_OWNER_DETAIL, userUseCases) ?
 
          <div
              className={classes.wrapper}
              onClick={ownerDetailOpen({
                ...rowData,
                editable: false,
                selectedRow: rowData.tableData.id
              })}
          >
            {rowData.ownerId}
          </div>: <>{rowData.ownerId}</>
        
      },
    },
    { title: t('SUBJECT'), field: 'subjectName', cellStyle,
    render: (rowData) => {
      return isComponentVisibleForUser(UC_OWNER_DETAIL, userUseCases) ?

        <div
            className={classes.wrapper}
            onClick={ownerDetailOpen({
              ...rowData,
              editable: false,
              selectedRow: rowData.tableData.id
            })}
        >
          {rowData.subjectName}
        </div>: <>{rowData.subjectName}</>
      
    }, },
    {
      title: t('VALIDITY'),
      field: 'valid',
      cellStyle,
      render: (rowData) => {
        return isComponentVisibleForUser(UC_OWNER_DETAIL, userUseCases) ?
          <EventType type={rowData.valid ? 'valid' : 'invalid'} 

            onClick={ownerDetailOpen({
              ...rowData,
              editable: false,
              selectedRow: rowData.tableData.id
            })}
          /> : <EventType type={rowData.valid ? 'valid' : 'invalid'} />
        },
    },
    { title: t('FIRST_NAME'), field: 'firstName', cellStyle,
    render: (rowData) => {
      return isComponentVisibleForUser(UC_OWNER_DETAIL, userUseCases) ?

        <div
            className={classes.wrapper}
            onClick={ownerDetailOpen({
              ...rowData,
              editable: false,
              selectedRow: rowData.tableData.id
            })}
        >
          {rowData.firstName}
        </div>: <>{rowData.firstName}</>
      
    }, },
    { title: t('LAST_NAME'), field: 'lastName', cellStyle,
    render: (rowData) => {
      return isComponentVisibleForUser(UC_OWNER_DETAIL, userUseCases) ?

        <div
            className={classes.wrapper}
            onClick={ownerDetailOpen({
              ...rowData,
              editable: false,
              selectedRow: rowData.tableData.id
            })}
                     
            
        >
          {rowData.lastName}
        </div>: <>{rowData.lastName}</>
      
    }, },
    {
      title:'',
      field: 'action',
      cellStyle,
      render: (rowData) => {
        return (
          <div className={classes.wrapper}>
            {/* {isComponentVisibleForUser(UC_OWNER_EDIT, userUseCases)
            && (
              <ActionButton
                disabled={!isComponentEnabledForUser(UC_OWNER_EDIT, userUseCases)}
                action="edit"
                className={classes.mr}
                handleClick={ownerDetailOpen({
                  ...rowData,
                  editable: true,
                })}
              />
            )} */}
            {(isComponentVisibleForUser(UC_OWNER_INVALIDATE, userUseCases)) && (
              <ActionButton
                disabled={!isComponentEnabledForUser(UC_OWNER_INVALIDATE, userUseCases)}
                handleClick={handleActivate(rowData.ownerId, !rowData.valid)}
                action={rowData.valid ? 'invalidate' : 'validate'}
              />
            )}
          </div>
        );
      },
    },
  ];

  const searchMenuData = subjectSearchOwners.length > 0
    ? subjectSearchOwners.map((data) => ({
      key: data.ownerId,
      label: `${data.firstname} ${data.surname}`,
      value: data.ownerId,
    }))
    : [];

  const newOwner = () => {
    ownerDetailOpen({});
    showForm(true);
  }
  const searchMenuItemClick = (value) => {
    let tmp =  subjectSearchOwners.length > 0
    ? subjectSearchOwners.filter((i) => i.ownerId === value).map((data) => ({
      key: data.ownerId,
      label: `${data.firstname} ${data.surname}`,
      value: data.ownerId,
    }))
    : [];

    handleOwnerDetailOpen({
      ownerId: value,
      ownerLabes: tmp?tmp[0].label:"",
      editable: false,
    });
  };
  const [selectedRow, setSelectedRow] = useState(null);

  return (
    <div className={classes.container}>
      <div className={classes.actionWrapper}>
        <div className={classes.filter}>
          <CustomMultiSelect
            items={validityStatus}
            label={t('VALIDITY')}
            value={validity}
            handleChange={handleValidity}
          />
        </div>

        <div className={classes.toolbarInput}>
          <CustomSearchInput
            placeholder={t('CARD_OWNER')}
            menuData={searchMenuData}
            handleChange={handleSearch}
            menuItemClick={searchMenuItemClick}
          />
        </div>

        <div className={classes.addButtonWrapper}>
          {isComponentVisibleForUser(UC_OWNER_CREATE, userUseCases) &&
          <IconButton
              className={classes.addButton}
              edge="start"
              color="inherit"
              aria-label="add owner"
              onClick={ownerDetailOpen({})}
              disabled={!isComponentEnabledForUser(UC_OWNER_CREATE, userUseCases)}
          >
            <Add className={classes.addIcon}/>
          </IconButton>
          }
        </div>
      </div>

      <CustomTable
        title=""
        columns={columns}
        data={ownersData}
        options={{
          headerStyle: {
            padding: '15px 8px 15px 34px',
            borderTop: '1px solid #DEE2E5',
            lineHeight: '12px',
            color: '#1B1B28',
            textAlign: 'center',
          },
          ...options
        }}
        isLoading={isLoadingOwners}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        rowsPerPage={ownersPaging.pageSize}
        pageNumber={ownersPaging.currentPage}
        count={ownersPaging.totalSize}
        loadAllData
      />
    </div>
  );
}

CardOwnersTable.propTypes = {
  ownersData: PropTypes.array.isRequired,
  subjectSearchOwners: PropTypes.array.isRequired,
  ownersPaging: PropTypes.object.isRequired,
  isLoadingOwners: PropTypes.bool.isRequired,
  validity: PropTypes.array.isRequired,
  ownerDetailOpen: PropTypes.func.isRequired,
  handleOwnerDetailOpen: PropTypes.func.isRequired,
  handleActivate: PropTypes.func.isRequired,
  handleValidity: PropTypes.func.isRequired,
  handleSearch: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
};

const UC_OWNER_DETAIL = 'UC0045';
const UC_OWNER_INVALIDATE = 'UC0046';
const UC_OWNER_EDIT = 'UC0047';
const UC_OWNER_CREATE = 'UC0048';


export default CardOwnersTable;
