import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { fetchSubjectOwners, fetchSubjectSearchOwners, activateOwner } from 'redux/actions/owners';
import CardOwnersTable from './CardOwnersTable';

const filterParams = {
  currentKeyword: '',
  validity: [''],
  size: 5,
  page: 0,
};

function CardOwners(props) {
  const {
    active,
    subjectOwners,
    subjectSearchOwners,
    ownersPaging,
    handleOwnerDetailOpen,
    fetchSubjectOwners,
    fetchSubjectSearchOwners,
    user,
    activateOwner,
    userUseCases,
  } = props;

  const isUnMounted = useRef(false);
  useEffect(() => {
    isUnMounted.current = false;
    return () => {
      isUnMounted.current = true;
    };
  }, []);

  const [isLoadingOwners, setIsLoadingOwners] = useState(true);
  const [isFetchedOwners, setIsFetchedOwners] = useState(false);
  const [isFetchedSearchOwners, setIsFetchedSearchOwners] = useState(true);
  const fetchOwners = useCallback((validity, searchKeyword, size, page) => {
    const validityStr = validity.map((valid) => {
      if (!valid) {
        return '';
      }

      return valid === 'valid';
    });

    if (
      user
      && user.subjectId
    ) {
      fetchSubjectOwners(user.subjectId, validityStr, searchKeyword, size, page)
        .then(() => {
          if (!isUnMounted.current) {
            setIsLoadingOwners(false);
            setIsFetchedOwners(true);
            if (
              JSON.stringify(filterParams.validity) !== JSON.stringify(validity)
              || filterParams.size !== size
              || filterParams.page !== page
            ) {
              setIsFetchedOwners(false);
            }
          }
        })
        .catch(() => {
          if (!isUnMounted.current) {
            setIsLoadingOwners(true);
            setIsFetchedOwners(false);
          }
        });
    }
  }, [fetchSubjectOwners, user]);

  const fetchSearchOwners = useCallback((keyword) => {
    if (keyword) {
      fetchSubjectSearchOwners(keyword)
        .then(() => {
          if (!isUnMounted.current) {
            setIsFetchedSearchOwners(true);
            if (filterParams.currentKeyword !== keyword) {
              setIsFetchedSearchOwners(false);
            }
          }
        })
        .catch(() => {
          if (!isUnMounted.current) {
            setIsFetchedSearchOwners(false);
          }
        });
    }
  }, [fetchSubjectSearchOwners]);

  const [validity, setValidity] = useState(['']);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [size, setSize] = useState(5);
  const [page, setPage] = useState(0);
  const resetPagination = () => {
    filterParams.page = 0;
    setPage(0);
  };
  const handleValidity = (value) => {
    let validity = value;
    if (validity.length === 3) {
      validity = [''];
    }
    filterParams.validity = validity;
    resetPagination();
    setIsFetchedOwners(false);
    setValidity(validity);
  };
  const handleChangeRowsPerPage = (value) => {
    filterParams.size = value;
    resetPagination();
    setIsFetchedOwners(false);
    setSize(value);
  };
  const handleChangePage = (value) => {
    filterParams.page = value;
    setIsFetchedOwners(false);
    setPage(value);
  };
  const handleSearch = (value) => {
    filterParams.currentKeyword = value;
    resetPagination();
    setIsFetchedSearchOwners(false);
    setIsFetchedOwners(false);
    setSearchKeyword(value);
  };

  useEffect(() => {
    if (!isFetchedOwners && active) {
      fetchOwners(validity, searchKeyword, size, page);
    }
  }, [validity, active, isFetchedOwners, size, page, fetchOwners, searchKeyword]);

  useEffect(() => {
    if (!isFetchedSearchOwners && active) {
      fetchSearchOwners(searchKeyword);
    }
  }, [active, isFetchedSearchOwners, fetchSearchOwners, searchKeyword]);

  const [ownersData, setOwnersData] = useState([]);
  useEffect(()=>{
    if ( subjectOwners ) {
      setOwnersData(subjectOwners.map((owner) => ({
        ownerId: owner.ownerId,
        firstName: owner.firstname,
        lastName: owner.surname,
        parkingName: owner.parkingName,
        subjectName: owner.subjectName,
        valid: owner.valid,
      })));
    }
  }, [subjectOwners]);

  const handleActivate = (ownerId, activate) => () => {
    activateOwner(ownerId, activate);
  };

  const ownerDetailOpen = (event) => () => {
    handleOwnerDetailOpen(event);
  };

  return (
    <CardOwnersTable
      userUseCases={userUseCases}
      ownersData={ownersData}
      subjectSearchOwners={subjectSearchOwners}
      ownersPaging={ownersPaging}
      isLoadingOwners={isLoadingOwners}
      validity={validity}
      ownerDetailOpen={ownerDetailOpen}
      handleOwnerDetailOpen={handleOwnerDetailOpen}
      handleActivate={handleActivate}
      handleValidity={handleValidity}
      handleSearch={handleSearch}
      handleChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangePage={handleChangePage}
    />
  );
}

CardOwners.propTypes = {
  active: PropTypes.bool.isRequired,
  subjectOwners: PropTypes.array.isRequired,
  subjectSearchOwners: PropTypes.array.isRequired,
  ownersPaging: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  handleOwnerDetailOpen: PropTypes.func.isRequired,
  fetchSubjectOwners: PropTypes.func.isRequired,
  fetchSubjectSearchOwners: PropTypes.func.isRequired,
  activateOwner: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => ({
  subjectOwners: store.ownersData.subjectOwners,
  ownersPaging: store.ownersData.ownersPaging,
  subjectSearchOwners: store.ownersData.subjectSearchOwners,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  fetchSubjectOwners,
  fetchSubjectSearchOwners,
  activateOwner,
}, dispatch);


export default React.memo(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CardOwners), (o1, o2)=>{return o2.active===false});

